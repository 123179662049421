/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Layout } from "../../layouts";
import { SelectChangeEvent, Select, MenuItem, Rating, Button } from "@mui/material";
import { AcupunctureModal } from "../../Modals";
import { useNavigate } from "react-router";
import moment from "moment";
import StarIcon from "@mui/icons-material/Star";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams } from "react-router-dom";
import { useGetDoctorByIdMutation } from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { Doctors, WeeklySchedule } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import LoginModal from "../../Modals/LoginModal";
import 'react-calendar/dist/Calendar.css';
import dayjs, { Dayjs } from 'dayjs';
import Calendar from 'react-calendar';
import { useLazyGetFaqQuery } from "../../services/cart";
import styled from "@emotion/styled";

export const DoctorDescription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [viewAll, setViewAll] = useState<boolean>(false);
  const buttonText = viewAll ? "Show less" : "View all";
  const userData = useAuth();
  const [getDoctorById, { isLoading }] = useGetDoctorByIdMutation();
  const [doctorData, setDoctorData] = useState<Doctors>();
  const [selectedTime, setSelectedTime] = useState<number>();
  const [details, setDetails] = useState<any>({
    startTime: '',
    endTime: '',
  });
  const [duration, setDuration] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [timingData, setTimingData] = useState<any[]>([]);
  const [slotArray, setSlotArray] = useState<any[]>([]);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());
  const [currentDate, setCurrentDate] = useState<any>(new Date());
  const [reviews, setReviews] = useState<any>([]);
  const [getFaq] = useLazyGetFaqQuery();
  const [faq, setFaq] = useState<any>();
  const [rating, setRating] = useState<any>("");
  const currentDate1 = moment().format('dddd');
  const currentTime = moment();
  const [currentDay, setCurrentDay] = useState<string>("");
  const [appointmentType, setAppointmentType] = useState<string>();
  console.log(appointmentType, "appointmentType");

  console.log(doctorData?.providers[0]?.visit_type[0], "doctorData?.providers[0]?.visit_type[0]");


  const fetchFaq = async () => {
    try {
      const res = await getFaq({}).unwrap();
      if (res?.status === 200) {
        setFaq(res)
      }
    } catch (errors: any) {
      showError(errors?.error)
    }
  }
  console.log(faq, "faq");

  useEffect(() => {
    fetchFaq();
  }, []);

  const handleCloseLoginModal = () => {
    setOpenModal(false);
  };

  console.log(currentDate, "current date");
  console.log(timingData, "timing data")

  const formatDate = (date: any) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };


  const handleTimingSlots = async () => {
    if (formatDate(currentDate).includes("Monday")) {
      setCurrentDay("Monday")
      setSlotArray(timingData[0]?.slots || [])
      setDuration(timingData[0]?.slot_duration)
    } else if (formatDate(currentDate).includes("Tuesday")) {
      setCurrentDay("Tuesday")
      setSlotArray(timingData[1]?.slots || [])
      setDuration(timingData[1]?.slot_duration)
    } else if (formatDate(currentDate).includes("Wednesday")) {
      setCurrentDay("Wednesday")
      setSlotArray(timingData[2]?.slots || [])
      setDuration(timingData[2]?.slot_duration)
    } else if (formatDate(currentDate).includes("Thursday")) {
      setCurrentDay("Thursday")
      setSlotArray(timingData[3]?.slots || [])
      setDuration(timingData[3]?.slot_duration)
    } else if (formatDate(currentDate).includes("Friday")) {
      setCurrentDay("Friday")
      setSlotArray(timingData[4]?.slots || [])
      setDuration(timingData[4]?.slot_duration)
    } else if (formatDate(currentDate).includes("Saturday")) {
      setCurrentDay("Saturday")
      setSlotArray(timingData[5]?.slots || [])
      setDuration(timingData[5]?.slot_duration)
    } else if (formatDate(currentDate).includes("Sunday")) {
      setCurrentDay("Sunday")
      setSlotArray(timingData[6]?.slots || [])
      setDuration(timingData[6]?.slot_duration)
    }
  }

  console.log(value, "date by calendar")

  useEffect(() => {
    handleTimingSlots();

  }, [currentDate, doctorData])


  // Function to handle click on the left arrow (previous day)
  const handlePrevDayClick = () => {
    const prevDate = new Date(currentDate);
    prevDate.setDate(prevDate.getDate() - 1); // Subtract 1 day
    setCurrentDate(prevDate);
  };


  // Function to handle click on the right arrow (next day)
  const handleNextDayClick = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1); // Add 1 day
    setCurrentDate(nextDate);
  };


  const [open, setOpen] = useState(false);
  const handleCloseModal = () => {
    setOpen(false);
  };
  console.log(doctorData, "doctorData---------->")

  const handleSlotClick = (time: number) => {

    if (userData) {
      setOpen(true);
      setSelectedTime(time);
    } else {
      setOpenModal(true);
    }
  };


  const firstTherapyPrice = doctorData?.pricing?.therapy_prices;

  let therapyItems: { name: string; price: number }[] = [];

  // Parse the therapy prices JSON string of the first object
  try {
    const therapyPricesObj = JSON.parse(firstTherapyPrice);
    if (therapyPricesObj && typeof therapyPricesObj === "object") {
      therapyItems = Object.entries(therapyPricesObj).map(
        ([name, price]: [string, any]) => ({
          name: name.replace(/_/g, " "),
          price: parseFloat(price),
        })
      );
    }
  } catch (error) {
    console.error("Error parsing therapy prices JSON:", error);
  }
  const getDoctorByIdQuery = async () => {
    const body = {
      id,
    };
    try {
      const response = await getDoctorById(body).unwrap();

      if (response?.status === 200) {
        setDoctorData(response?.user);
        setTimingData(response?.user?.shifts)
        setReviews(response?.user?.review)
        setRating(response?.rating)
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  console.log(timingData, "timing data");
  const [selectField, setSelectField] = React.useState("0");
  const [selectedPrice, setSelectedPrice] = useState(0);

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#FFD700',
    },
    '& .MuiRating-iconHover': {
      color: '#FFC107',
    },
  });

  useEffect(() => {
    check();
  }, [selectField]);

  useEffect(() => {
    setAppointmentType(String(doctorData?.providers[0]?.visit_type[0]))
  }, [doctorData])

  const check = () => {
    const selectedItem = therapyItems?.find(
      (item) => item?.name == selectField
    );
    if (selectedItem) {
      setSelectedPrice(selectedItem?.price);
    } else {
      setSelectedPrice(0);
    }
  };

  console.log(formatDate(currentDate), "current date,.,.,,,.")

  const capitalizeFirstLetter = (string: string) => {
    if (typeof string !== "string") return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleChange = (event: SelectChangeEvent) => {
    // const selectedItem = therapyItems?.find(
    //   (item) => item?.name == selectField
    // );
    // if (selectedItem) {
    //   setSelectedPrice(selectedItem?.price);
    // } else {
    //   setSelectedPrice(0);
    // }
    setSelectField(event.target.value as string);
  };

  const sub = doctorData?.providers[0]?.subcategories;
  const parsed = JSON.parse(sub || '[]');
  const cleaned = parsed.join(', ');


  console.log(selectedPrice, "price");

  const [selectField2, setSelectField2] = React.useState("1");
  console.log(selectField2, "ok")
  const handleChange2 = (event: SelectChangeEvent) => {
    setSelectField2(event.target.value as string);
  };

  const handleAppointmentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAppointmentType(event.target.value);
  };

  console.log(slotArray, "slotArray for appointment");


  const isDateInPast = (date: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const isDateOutOfRange = (date: any) => {
    const today = new Date();
    const oneWeekFromToday = new Date();
    oneWeekFromToday.setDate(today.getDate() + 6);
    return date > oneWeekFromToday;
  };

  useEffect(() => {
    getDoctorByIdQuery();
  }, []);

  return (
    <>
      <Layout>
        <Loader isLoad={isLoading} />
        <main className="content doctorListing_page">
          <section className="site_breadcrum">
            <div className="conta_iner">
              <ul>
                <li>
                  <a onClick={() => navigate("/")}>Home</a>
                </li>
                <li>
                  <a onClick={() => navigate("/docterlisting")}>
                    Doctor Listing
                  </a>
                </li>
                <li>
                  {`Dr. ${doctorData?.first_name || ""} ${doctorData?.last_name || ""
                    }`}
                </li>
              </ul>
            </div>
          </section>

          <section className="doctorDetail_sc ub_spc">
            <div className="conta_iner">
              <div className="gap_p">
                <div className="left_s">
                  <div className="doctor_top">
                    <figure className="doctor_image">
                      <img
                        src={
                          doctorData?.profile_photo
                            ? doctorData?.profile_photo
                            : "/static/images/doctor.png"
                        }
                        alt="icon"
                      />
                    </figure>
                    <div className="detail_area hd_3">
                      <p className="c_primary">{therapyItems[0]?.name}</p>
                      <h2>
                        {`Dr. ${doctorData?.first_name || "-"} ${doctorData?.last_name || ""
                          }`}
                      </h2>
                      {doctorData?.experience ? (
                        <p>{`${doctorData?.experience} years of experience`}</p>
                      ) : (
                        ""
                      )}
                      <address className="store_address">
                        <strong>{doctorData?.institute_name || ""}</strong>
                        {doctorData?.institute_address || ""}
                      </address>

                      <div className="store_rating">
                        <StarIcon /> {rating || "0"}
                      </div>
                    </div>
                  </div>
                  <div className="detailed_info">
                    <div className="hd_3">
                      <h2 className="fw_med">
                        About{" "}
                        {`Dr. ${doctorData?.first_name || ""} ${doctorData?.last_name || ""
                          }`}
                      </h2>
                    </div>
                    <p>{doctorData?.about_us ? doctorData?.about_us : "-"}</p>

                    {/* <div className="hd_5">
                      <h3 className="fw_med">Board Certifications</h3>
                    </div>
                    <p> <figure >
                      <img
                        width="350px"
                        src={
                          doctorData?.degree
                            ? doctorData?.degree
                            : "/static/images/doctor.png"
                        }
                        alt="icon"
                      />
                    </figure></p> */}


                    <div className="hd_5">
                      <h3 className="fw_med">Education and Training</h3>
                    </div>
                    <p>{doctorData?.education_details || "-"}</p>

                    <div className="hd_5">
                      <h3 className="fw_med">Specialties</h3>
                    </div>
                    <p>{cleaned || "-"}</p>

                    <div className="hd_5">
                      <h3 className="fw_med">Practice names</h3>
                    </div>
                    <p>{doctorData?.providers[0]?.practice_name}</p>

                    <div className="hd_5">
                      <h3 className="fw_med">Provider’s gender</h3>
                    </div>
                    <p>{doctorData?.gender ? capitalizeFirstLetter(doctorData.gender) : "-"}</p>
                    <div className="hd_5">
                      <h3 className="fw_med">License Number</h3>
                    </div>
                    <p>{doctorData?.license_number || "-"}</p>
                  </div>
                </div>
                <div className="right_s">
                  <aside className="doctor_aside">
                    <div className="card_box">
                      <h6>Book Appointment</h6>
                      <form action="" className="form">
                        <div className="control_group">
                          <div className="appoint_select">
                            {(appointmentType === "videovisit" || doctorData?.providers[0]?.visit_type?.length !== 1) ? (
                              <label>
                                <input
                                  type="radio"
                                  name="appointment"
                                  value="videovisit"
                                  checked={appointmentType === "videovisit"}
                                  onChange={handleAppointmentTypeChange}
                                />
                                Online consultant
                              </label>
                            ) : ("")}
                            {(appointmentType === "inperson" || doctorData?.providers[0]?.visit_type?.length !== 1) ? (
                              <label>
                                <input
                                  type="radio"
                                  name="appointment"
                                  value="inperson"
                                  checked={appointmentType === "inperson"}
                                  onChange={handleAppointmentTypeChange}
                                />
                                Visit Doctor Clinic
                              </label>
                            ) : ("")}

                          </div>
                        </div>
                        <div className="control_group">
                          <Select
                            fullWidth
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            label="social-account"
                            value={selectField}
                            onChange={handleChange}
                          >
                            <MenuItem disabled value="0">
                              Select category
                            </MenuItem>
                            {therapyItems?.map((item) => (
                              <MenuItem value={item?.name}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <div className="control_group">
                          <Select
                            fullWidth
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            label="social-account"
                            value={selectField2}
                            onChange={handleChange2}
                          >
                            <MenuItem disabled value="0">
                              Patient type
                            </MenuItem>
                            <MenuItem value="1">New Patient</MenuItem>
                            <MenuItem value="2">Returning Patient</MenuItem>
                          </Select>
                        </div>

                        <div className="slots">
                          <h6>Select Slot</h6>
                          <div className="doctor_date">
                            <a onClick={handlePrevDayClick}>
                              <ArrowBackIosNewIcon style={{ cursor: "pointer" }} />
                            </a>
                            <span
                              onClick={() => {
                                setOpenCalendar(!openCalendar); // Toggles the current state
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {currentDate ? formatDate(currentDate) : ""}
                            </span>
                            <a onClick={handleNextDayClick}>
                              <ArrowForwardIosIcon style={{ cursor: "pointer" }} />
                            </a>
                          </div>

                          {openCalendar && (
                            <div className="calendar-overlay">
                              <Calendar
                                value={currentDate}
                                onChange={(newValue) => {
                                  if (newValue && !isDateInPast(newValue) && !isDateOutOfRange(newValue)) {
                                    setCurrentDate(newValue);
                                    setOpenCalendar(false);
                                  }
                                }}
                                tileDisabled={({ date, view }) =>
                                  view === 'month' && (isDateInPast(date) || isDateOutOfRange(date))
                                }
                              />
                            </div>
                          )}
                          <div className="slot_list">
                            {slotArray?.length ? (
                              slotArray.filter((item) => {
                                const slotEndTime = moment(item?.slot_start_time, 'HH:mm:ss');
                                if (currentDay === currentDate1) {
                                  return slotEndTime.isAfter(currentTime);
                                }
                                return true;
                              })?.map((item: any) => (
                                <a
                                  key={item?.id}
                                  onClick={() => {
                                    if (item?.isbooked !== 1) {
                                      handleSlotClick(item?.id);
                                      setDetails({
                                        startTime: item?.slot_start_time,
                                        endTime: item?.slot_end_time
                                      });
                                    }
                                  }}
                                  className={item?.isbooked === 1 ? 'disabled' : ''}
                                >
                                  {moment(item?.slot_start_time, 'HH:mm:ss').format('hh:mm A') + "-" + moment(item?.slot_end_time, 'HH:mm:ss').format('hh:mm A')}
                                </a>
                              ))
                            ) : (
                              <p style={{ color: "gray", textAlign: "center" }}>No slot available for today</p>
                            )
                            }
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card_box">
                      <h6>Pricing</h6>
                      <ul className="price_list scrollable-list">
                        {therapyItems?.length > 0
                          ? therapyItems?.map((item) => (
                            <li>
                              {item?.name} <strong>${item?.price}</strong>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  </aside>
                </div>
              </div>

              {/* REVIEWS */}
              {reviews?.length ? (
                <div className="faq_sc uht_spc">
                  <div className="s_head hd_3">
                    <h2 className="fw_med">Reviews</h2>
                  </div>
                  <ul className="faq_list" style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {reviews?.length ?
                      (
                        reviews?.map((item: any) => {
                          return (
                            <li>
                              <strong>
                                {item?.patient?.name}  <StyledRating value={item?.rating} readOnly size="small" />
                              </strong>
                              <small>
                                {item?.review}
                              </small>
                            </li>
                          )
                        })
                      ) : (
                        ""
                      )}
                  </ul>
                  {/* {reviews?.length > 3 && (
                    <a
                      style={{
                        backgroundColor: "white", color: "#29AB87", cursor: "pointer", textDecoration: "underline"
                      }}
                      className="btn btn_primary"
                      onClick={() => {
                        setViewAll(!viewAll)
                      }}
                    >
                      {buttonText}
                    </a>
                  )} */}
                </div>
              ) : ("")}


              <div className="faq_sc uht_spc">
                <div className="s_head hd_3">
                  <h2 className="fw_med">Frequently Asked Questions</h2>
                </div>
                <ul className="faq_list">
                  {faq?.users?.data?.length ?
                    (
                      faq?.users?.data?.map((item: any) => {
                        return (
                          <li>
                            <strong>
                              {item?.question}
                            </strong>
                            <small>
                              {item?.answer}
                            </small>
                          </li>
                        )
                      })
                    ) : (
                      ""
                    )}
                </ul>
              </div>
            </div>
          </section>
        </main >
      </Layout >
      <AcupunctureModal
        open={open}
        details={details}
        duration={duration}
        onClose={handleCloseModal}
        setOpen={setOpen}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectField={selectField}
        currentDate={currentDate}
        setDetails={setDetails}
        setCurrentDate={setCurrentDate}
        doctorData={doctorData}
        selectedPrice={selectedPrice}
        selectField2={selectField2}
        setSelectField2={setSelectField2}
        slotArray={slotArray}
        appointmentType={appointmentType || ""}
        currentDate1={currentDate1}
        currentTime={currentTime}
        currentDay={currentDay}
      />
      <LoginModal
        open={openModal}
        setOpen={setOpenModal}
        onClose={handleCloseLoginModal}
      />
    </>
  );
};
