/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import OrderInvoice from '../../Modals/orderInvoiceModal';
import { Order } from '../../types/General';
import { useLazyGetOrderHistoryQuery } from '../../services/cart';
import { showError } from '../../constants';
import { useLazyGetOrderInvoiceQuery } from '../../services/main';

export default function OrderHistory() {
    const [open, setOpen] = useState(false);
    const [getOrders] = useLazyGetOrderHistoryQuery();
    const [orders, setOrders] = useState<any>();
    const [itemData, setItemData] = useState<Order>();
    const handleCloseModal = () => {
        setOpen(false);
    };
    const [downloadInvoice] = useLazyGetOrderInvoiceQuery();


    const fetchOrders = async () => {
        try {
            const res = await getOrders({}).unwrap();
            if (res?.status === 200) {
                setOrders(res);
            }
        } catch (errors: any) {
            showError(errors?.error);
        }
    }


    const fetchInvoice = async (id: any) => {
        try {
            const res = await downloadInvoice({ id: id }).unwrap();
            if (res?.status === 200) {
                window.open(res?.url);
                console.log(res, "rsess");

            }
        } catch (error: any) {
            showError(error?.message);
        }
    };




    useEffect(() => {
        fetchOrders();
    }, [])

    return (
        <>
            <div className="tab-pane">
                <div className="account_head">
                    <h3>Orders History</h3>
                </div>
                {
                    orders?.orders?.length ? (
                        orders?.orders?.map((item: Order) => {
                            return (
                                <div className="orders_box">
                                    <div className="head">
                                        <ul>
                                            <li>
                                                <span>Order ID</span>
                                                <strong>#{item?.order_number}</strong>
                                            </li>
                                            <li>
                                                <span>Order Placed</span>
                                                <strong>{item?.order_date_display}</strong>
                                            </li>
                                            <li>
                                                <span>Total</span>
                                                <strong>${item?.total_amount}</strong>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li><a href=" " className="btnn btn_primary"
                                                onClick={() => fetchInvoice(item?.id)}
                                            >Download Invoice</a></li>
                                        </ul>
                                    </div>
                                    <div className="body">
                                        <div className="order_table table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        item?.sold_product?.map((temp) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <figure className="order_image">
                                                                            <img src={temp?.image} alt="icon" />
                                                                            <figcaption>{temp?.product_name}</figcaption>
                                                                        </figure>
                                                                    </td>
                                                                    <td>${temp?.price_per_product}</td>
                                                                    <td>{temp?.quantity}</td>
                                                                    <td>${temp?.quantity * Number(temp?.price_per_product)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="head">
                                        <ul>
                                            <li>
                                                <span>Status: {item?.order_status.toUpperCase()}</span>
                                                <strong>
                                                    {new Date(item?.delivery_date) > new Date() ? " Deliver by" : "Delivered on"
                                                    } {item?.delivery_date_display}
                                                </strong>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li><a href="javascript:void(0)" onClick={() => { setOpen(true); setItemData(item) }} data-bs-toggle="modal" data-bs-target="#orderInvoiceModal">View Invoice</a></li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <img
                                    src="/static/images/noapp.png"
                                    alt="icon"
                                    style={{ height: "100px", width: "100px", margin: "auto" }}
                                />
                                <h5>No recent orders</h5>
                            </div>
                        </div>
                    )
                }


            </div>

            <OrderInvoice open={open} onClose={handleCloseModal} setOpen={setOpen} item={itemData} />
        </>
    )
}
