// @ts-nocheck
import CloseIcon from "@mui/icons-material/Close";
import {
  Modal,
  Button,
  TextField,
  SelectChangeEvent,
  Select,
  MenuItem,
  Box,
  FormControl,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";

import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import { usePostProfileSetupMutation, useVerifyOtpMutation } from "../../services/auth";
import {
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import "react-phone-input-2/lib/bootstrap.css";
import { isNumber, isString } from "../../utils/validations";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import OTPInput from "react-otp-input";
import { usePostChangeEmailMutation } from "../../services/main";
import CancelIcon from "@mui/icons-material/Cancel";
import { UploadMedia } from "../../utils/mediaUpload";

interface editProfileModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const otpStyle = {
  width: "60px",
  height: "60px",
  "box-sizing": "border-box",
  margin: "0 auto",
};
export default function EditProfile({
  open,
  onClose,
  setOpen,
}: editProfileModalProps) {
  const userData = useAuth();
  const dispatch = useAppDispatch();
  const [updateProfile] = usePostProfileSetupMutation();
  const [updateEmail] = usePostChangeEmailMutation();
  const userId = getFromStorage(STORAGE_KEYS.userId);
  const [num, setNum] = useState<number>(0);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [verifyOTP] = useVerifyOtpMutation();
  const [value, setValue] = useState("default");
  const [error, setError] = useState(false);
  const [selectField, setSelectField] = React.useState("default");
  const [isVerifyEmail, setVerifyEmail] = useState<boolean>(false);
  const [isVerifyPhone, setVerifyPhone] = useState<boolean>(false)
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState<number>(59);
  const getData = getFromStorage(STORAGE_KEYS.res) as any;

  const handleChange = (event: SelectChangeEvent) => {
    setSelectField(event.target.value as string);
  };

  console.log(userData, "userData---->");


  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    // Check if a file is selected
    try {
      if (file) {
        if (file.type.startsWith("image/")) {
          console.log(file, "FILE");
          const res = await UploadMedia(file);
          if (res?.status === 200) {
            formik.setFieldValue("image", res?.url);
            showToast("Image uploaded successfully");
          } else {
            showError(res?.message);
          }
        } else {
          showError("Please select a valid image");
        }
      }
    } catch (error: any) {
      showError(error.error);
      console.log(error, "error");
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone_number", phone_number?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };
  // console.log(formik.values.countryCode, "country code");

  const handleSubmit = async (str: string) => {
    let body = {};
    if (str === "email") {
      body = {
        id: userId,
        email: formik.values.email,
      }
    } else if (str === "phone") {
      body = {
        id: userId,
        phone_number: formik.values.phone_number,
        country_code: formik.values.country_code
      }
    } else {
      body = {
        id: userId,
        email: formik.values.email,
      }
    }
    // const token = getFromStorage(STORAGE_KEYS.token);
    console.log(body, "Update email ki body");
    try {
      const res = await updateEmail(body).unwrap();
      // setOtp("l");
    } catch (error: any) {
      if (error?.data?.message) {
        showError(error?.data?.message || "");
      }
    }
  };
  // console.log(num, "= num");

  const handleOtpVerification = async () => {
    if (otp?.length === 4) {
      setError(false);
      let body = {};
      if (num === 1) {
        body = {
          otp: otp,
          email: formik.values.email,
        }
      } else if (num === 2) {
        body = {
          otp: otp,
          phone_number: formik.values.phone_number,
          country_code: formik.values.country_code
        }
      }
      console.log(body, "Verify otp ki body")
      try {
        const token = getFromStorage(STORAGE_KEYS.token);
        const res = await verifyOTP(body).unwrap();
        if (res.status === 200) {
          showToast("Email/phone number verified successfullly");
          setVerifyEmail(false);
          setVerifyPhone(false);
          setOtp("");
          dispatch(
            setCredentials({
              user: res?.user,
              token: token,
            })
          );
        }
      } catch (error: any) {
        // const errorMessage = error?.message || "An error occurred while verifying OTP";
        console.log(error, "errorerror");

        showError(error?.data?.message);
      }
    } else {
      setError(true);
      showError("OTP must be 4 digits");
    }
  };


  const handleChangeStatus = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userData?.email || "",
      fullName: userData?.name || "",
      phone_number: userData?.phone_number || "",
      country_code: userData?.country_code,
      dob: userData?.DOB || "",
      gender: "",
      city: userData?.city || "",
      zipcode: userData?.zipcode || "",
      height: userData?.height?.toString() || "",
      weight: userData?.weight?.toString() || "",
      martialStatus: "",
      address: userData?.country || "",
      occupation: userData?.occupation || "",
      image: userData?.profile_photo || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Please enter a valid email"
        ),
      fullName: Yup.string()
        .required("Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(100, "Maximum 100 characters is required"),
      phone_number: Yup.string()
        .required("Phone number is required")
        .min(6, "Minimum 6 digits are required")
        .max(16, "Maximum 16 digits are required"),
      city: Yup.string()
        .required("City is required"),
      zipcode: Yup.string()
        .required("zipcode is required"),
      country_code: Yup.string().notRequired(),
      dob: Yup.string().required("Date of Birth is required"),
      height: Yup.string()
        .required("Height is required")
        .matches(
          /^\d{1,3}(\.\d{0,2})?$/,
          "Height must be a valid number with a maximum of 3 digits and up to 2 decimal places"
        ),
      weight: Yup.string()
        .required("Weight is required")
        .matches(
          /^\d{1,3}$/,
          "Weight must be a valid number with a maximum of 3 digits"
        ),
      address: Yup.string().required("Address is required").max(200, "You can't add more than 200 characters"),
      occupation: Yup.string().required("Occupation is required").max(100, "You can't add more than 100 characters")
        .matches(
          /^[A-Za-z\s'-]+$/,
          "Occupation must not contain numbers"
        ),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      if (userData?.email !== formik.values.email) {
        showError("Please verify your email first");
        return;
      }

      let body = {
        profile_photo: values.image,
        email: values.email,
        name: values.fullName,
        city: values.city,
        zipcode: values.zipcode,
        phone_number: formik.values.phone_number,
        country_code: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        DOB: values.dob,
        gender: selectField,
        height: values.height,
        weight: values.weight,
        marital_status: value,
        country: values.address,
        occupation: values.occupation,
        id: userId,
      };

      if (userData?.email_verified !== "1") {
        showError("Please verify your Email-Id");
        return;
      }

      if (userData?.phone_number_verified !== 1) {
        showError("Please verify your Phone number");
        return;
      }

      const token = getFromStorage(STORAGE_KEYS.token);
      try {
        const response = await updateProfile(body).unwrap();
        if (response?.status === 200) {
          showToast("Profile updated successfully");
          setOpen(false);
          dispatch(
            setCredentials({
              user: response?.data,
              token: token,
            })
          );
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  useEffect(() => {
    if (userData) {
      setSelectField(userData?.gender);
      setValue(userData?.marital_status);
    }
  }, []);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  useEffect(() => {
    if (getData) {
      formik.setFieldValue("countryCode", JSON.parse(getData)?.country_code);
    }
  }, []);


  return (
    <div className="modal-dialog">
      <div className="modal-body hd_4">
        <div className="btn-close" onClick={() => setOpen(false)}>
          <CloseIcon />
        </div>
        <h2>{isVerifyEmail || isVerifyPhone ? "Verify Email/Phone" : "Edit Profile"}</h2>
        {isVerifyEmail || isVerifyPhone ? (
          <Box>
            <div className="auth_parnt">
              <div className="auth_left">
                <p>
                  {isVerifyEmail
                    ? (
                      <div style={{textAlign:"center"}}>
                        We have sent a verification code to your email at{' '}
                        <br />
                        <span style={{ fontWeight: 'bold' ,color:"#29AB87"}}>{formik.values.email}</span>
                      </div>
                    )
                    : (
                      <div style={{textAlign:"center"}}>
                        We have sent a verification code to your phone number at{' '}
                        <br />
                        <span style={{ fontWeight: 'bold',color:"#29AB87" }}>
                          {formik.values.country_code} {formik.values.phone_number}
                        </span>
                      </div>
                    )
                  }
                </p>

                <Box sx={{ paddingBottom: 5 }}></Box>
                <FormControl className="opt_fields" sx={{ width: "100%" }}>
                  <OTPInput
                    containerStyle={{ width: "70%", margin: "auto" }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={otpStyle}
                    inputType="tel"
                  />
                  <br />
                  {error && otp.length !== 4 ? (
                    <h6 className="err_msg" style={{ textAlign: "center" }}>
                      Otp is required
                    </h6>
                  ) : (
                    ""
                  )}
                </FormControl>

                <div className="form_btn">
                  <button
                    type="submit"
                    className="btnn btn_xsm btn_primary w_100"
                    onClick={handleOtpVerification}
                  >
                    Next
                  </button>
                </div>
                {countDown === 0 ? (
                  <Box
                    sx={{
                      textAlign: "center",
                      pt: 2,
                    }}
                  >
                    <Typography
                      className="anchor_link"
                      sx={{ cursor: "pointer", fontWeight: 600 }}
                      onClick={() => {
                        showToast("Otp resent successfully");
                      }}
                    >
                      Resend Otp
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      sx={{
                        color: "grey",
                        pt: 2,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      code will expire in{" "}
                      <p
                        style={{
                          margin: 0,
                          color: "#d63853",
                          paddingLeft: 5,
                          minWidth: 70,
                          fontSize: "16px",
                        }}
                      >
                        {countDown < 10
                          ? `00: 0${countDown}`
                          : `00: ${countDown}`}
                      </p>
                    </Typography>
                  </Box>
                )}
              </div>
            </div>
          </Box>
        ) : (
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="gap_p">
              <div className="control_group text_center">
                {formik.values.image ? (
                  <div className="upload_image">
                    <figure>
                      <img
                        src={
                          formik.values.image
                            ? formik.values.image
                            : "static/images/dummy.png"
                        }
                        alt="icon"
                      />
                    </figure>
                    <div className="action">
                      <CancelIcon
                        onClick={() => {
                          formik.setFieldValue("image", "");
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="upload_image">
                    <figure>
                      <img src="static/images/dummy.png" alt="icon" />
                    </figure>
                    <div className="action">
                      <AddIcon />
                      <input
                        placeholder="img"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="control_group w_50">
                <label>Full name</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter here"
                  className="text_field"
                  fullWidth
                  name="fullName"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
              </div>
              <div className="control_group w_50">
                <label className="gap_p aic jcsb">
                  <span className="w_auto">Email Id</span>
                  {(formik.values.email &&
                    userData?.email !== formik.values.email) || (userData?.email_verified === "0") ? (
                    <span
                      className="w_auto c_primary type_anchor"
                      onClick={() => {
                        setNum(1);
                        handleSubmit("email");
                        setVerifyEmail(true);
                      }}
                    >
                      Verify
                    </span>
                  ) : (
                    ""
                  )}
                </label>
                <TextField
                  hiddenLabel
                  placeholder="Enter here"
                  className="text_field"
                  fullWidth
                  type="email"
                  // disabled={Boolean(userData?.email)}
                  name="email"
                  value={formik.values.email}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className="control_group w_50">
                {/* <label>Phone number</label> */}
                <label className="gap_p aic jcsb">
                  <span className="w_auto">Phone number</span>
                  {(formik.values.phone_number &&
                    userData?.phone_number !== formik.values.phone_number) || (userData?.phone_number_verified === 0) ? (
                    <span
                      className="w_auto c_primary type_anchor"
                      onClick={() => {
                        // sendOtp("email");
                        setNum(2);
                        handleSubmit("phone");
                        setVerifyPhone(true);
                      }}
                    >
                      Verify
                    </span>
                  ) : (
                    ""
                  )}
                </label>
                <PhoneInput
                  country={"us"}
                  placeholder="0 (000) 000-000"
                  enableSearch={true}
                  inputStyle={{ width: "100%" }}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  onBlur={formik.handleBlur}
                  // onKeyDown={(e) => {  
                  //   if (e.key === "Enter") {
                  //     e.preventDefault(); // Prevent default behavior (form submission)
                  //     formik.handleSubmit(); // Manually submit the form
                  //   }
                  // }}
                  value={phoneCode + formik.values.phone_number}
                />
                {formik.touched.phone_number && formik.errors.phone_number ? (
                  <h6 className="err_msg">
                    {formik.touched.phone_number && formik.errors.phone_number}
                  </h6>
                ) : (
                  ""
                )}
              </div>
              <div className="control_group w_50">
                <label>Gender</label>
                <Select
                  fullWidth
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  label="social-account"
                  value={selectField || "default"}
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem disabled value="default">
                    Select
                  </MenuItem>
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Non-binary"}>Non-Binary</MenuItem>
                </Select>
                {error && !selectField ? (
                  <h6 className="err_msg">Gender is required</h6>
                ) : (
                  ""
                )}
              </div>
              <div className="control_group w_50">
                <label>Date of birth</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter here"
                  fullWidth
                  name="dob"
                  className="text_field"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dob}
                  helperText={formik.touched.dob && formik.errors.dob}
                  inputProps={{
                    // max: new Date().toISOString().split("T")[0],
                    max: moment().subtract(18, "years").format("YYYY-MM-DD"),
                  }}
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  InputProps={{ disableUnderline: true }}
                />
              </div>
              <div className="control_group w_50">
                <label>Height (cm)</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter here"
                  fullWidth
                  className="text_field"
                  name="height"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.height}
                  helperText={formik.touched.height && formik.errors.height}
                />
              </div>
              <div className="control_group w_50">
                <label>Weight (kg)</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter here"
                  fullWidth
                  className="text_field"
                  name="weight"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isNumber(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.weight}
                  helperText={formik.touched.weight && formik.errors.weight}
                />
              </div>
              <div className="control_group w_50">
                <label>Martial status</label>
                <Select
                  fullWidth
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  label="social-account"
                  value={value || "default"}
                  onChange={handleChangeStatus}
                  name="gender"
                >
                  <MenuItem disabled value="default">
                    Select
                  </MenuItem>
                  <MenuItem value={"Married"}>Married</MenuItem>
                  <MenuItem value={"Un-married"}>Un-married</MenuItem>
                </Select>
                {error && !value ? (
                  <h6 className="err_msg">Martial status is required</h6>
                ) : (
                  ""
                )}
              </div>
              <div className="control_group w_50">
                <label>Address</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter here"
                  fullWidth
                  className="text_field"
                  name="address"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (val.target.value) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </div>
              <div className="control_group w_50">
                <label>City</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter here"
                  fullWidth
                  className="text_field"
                  name="city"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (val.target.value) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </div>
              <div className="control_group w_50">
                <label>Zip Code</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter here"
                  fullWidth
                  className="text_field"
                  name="zipcode"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (val.target.value) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.zipcode}
                  helperText={formik.touched.zipcode && formik.errors.zipcode}
                />
              </div>
              <div className="control_group w_50">
                <label>Occupation</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter here"
                  className="text_field"
                  fullWidth
                  name="occupation"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.occupation}
                  helperText={
                    formik.touched.occupation && formik.errors.occupation
                  }
                />
              </div>
            </div>
            <div className="btn_flex">
              <a
                className="c_danger"
                onClick={() => setOpen(false)}
              >
                Cancel
              </a>
              <Button
                className="btnn btn_xsm btn_primary"
                type="submit"
                onClick={() => setError(true)}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
