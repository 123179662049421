import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../layouts";
import { TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { usePostProfileSetupMutation } from "../../services/auth";
import { useFormik } from "formik";
import {
  showToast,
  showError,
  getFromStorage,
  STORAGE_KEYS,
} from "../../constants";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import Skip from "../../components/skip";
import useAuth from "../../hooks/useAuth";

const Step1 = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [updateProfile] = usePostProfileSetupMutation();
  const location = useLocation();
  const state = location;
  const id = getFromStorage(STORAGE_KEYS.stepID);
  const dispatch = useAppDispatch();
  console.log(state, "uuuuuuuuuuuuuuuuuuuuuuu");

  const formik = useFormik({
    initialValues: {
      medical_reports: userData?.medical_reports || "",
      date: userData?.date || "",
      test_results: userData?.test_results || "",
      prescribed_by: userData?.prescribed_by || "",
      test_center: userData?.test_center || "",
    },
    validationSchema: Yup.object({
      medicalReports: Yup.string()
        .optional()
        .matches(
          /^[a-zA-Z0-9 ]+$/,
          "Only alpha-numeric characters are allowed"
        )
        .max(50, "Maximum 50 characters are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        medical_reports: formik.values.medical_reports,
        date: formik.values.date,
        test_results: formik.values.test_results,
        prescribed_by: formik.values.prescribed_by,
        test_center: formik.values.test_center,
        id: id,
      };

      try {
        const response = await updateProfile(body).unwrap();
        navigate("/step-2", { state: state });
        const token = getFromStorage(STORAGE_KEYS.token);
        dispatch(
          setCredentials({
            user: response?.data || null,
            token: token || null,
          })
        );
        if (response.status === 200) {
          navigate("/step-2");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  useEffect(() => {
    console.log(userData, "userData");

  }, [])

  return (
    <Layout>
      <main className="content auth_page">
        <section className="auth_sc uh_spc">
          <div className="conta_iner">
            <div className="inner">
              <div className="hd_4 text_center">
                <a
                  onClick={() => navigate("/profile-setup")}
                  className="back_icon"
                >
                  <ArrowBackIcon />
                </a>
                <h2>Health data</h2>
                <Skip />

              </div>
              <div className="hd_6">
                <h3>Medical records</h3>
              </div>
              <form
                onSubmit={formik.handleSubmit}
                className="form"
                method="post"
              >
                <div className="control_group">
                  <label>Name of medical reports</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter here"
                    fullWidth
                    inputProps={{
                      maxLength: 50
                    }}
                    value={formik.values.medical_reports}
                    onChange={(event) => {
                      const regex = /^[a-zA-Z0-9 ]*$/;
                      const input = event.target.value;
                      if (regex.test(input)) {
                        formik.setFieldValue("medical_reports", input);
                      }
                    }}
                  />
                </div>
                <div className="control_group">
                  <label>Date</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter here"
                    fullWidth
                    type="date"
                    value={formik.values.date}
                    // onChange={(event) =>
                    //   formik.setFieldValue("date", event.target.value)
                    // }
                    onChange={(event) => {
                      const value = event.target.value;
                      const currentDate = new Date().toISOString().split("T")[0];
                      if (value <= currentDate) {
                        formik.setFieldValue("date", value);
                      } else {
                        showError("Future dates are not allowed");
                      }
                    }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0], // Restrict future dates
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent form submission on "Enter"
                      }
                    }}
                  />
                </div>
                <div className="control_group">
                  <label>Test results</label>
                  <TextField
                    hiddenLabel
                    inputProps={{ maxLength: 50 }}
                    placeholder="Enter here"
                    fullWidth
                    value={formik.values.test_results}
                    onChange={(event) =>
                      formik.setFieldValue("test_results", event.target.value)
                    }
                  />
                </div>
                <div className="control_group">
                  <label>Prescribed by</label>
                  <TextField
                    hiddenLabel
                    inputProps={{ maxLength: 50 }}
                    placeholder="Enter here"
                    fullWidth
                    value={formik.values.prescribed_by}
                    onChange={(event) =>
                      formik.setFieldValue("prescribed_by", event.target.value)
                    }
                  />
                </div>
                <div className="control_group">
                  <label>Test center</label>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    hiddenLabel
                    placeholder="Enter here"
                    fullWidth
                    value={formik.values.test_center}
                    onChange={(event) =>
                      formik.setFieldValue("test_center", event.target.value)
                    }
                  />
                </div>
                <div className="form_btn">
                  <button
                    type="submit"
                    className="btnn btn_xsm btn_primary w_100"
                  // onClick={() => navigate("/step-2")}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
    </Layout >
  );
};

export default Step1;
