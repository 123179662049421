/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
// checkout.tsx
import React, { useEffect, useState } from "react";
import CheckoutSideItems from "../../components/checkoutSideItems";
import SimilarProductCard from "../../components/similarProductCard";
import CheckoutAddress from "../../components/checkoutAddress";
import cardData from "../../data/ProductData.json";
import AddCardModal from "../../Modals/addCardModal";
import { Layout } from "../../layouts";
import { useNavigate } from "react-router";
import { AddressModal } from "../../Modals";
import AddIcon from "@mui/icons-material/Add";
import { useLazyGetAllAddressQuery } from "../../services/profile";
import { CardData, HerbalProduct, UserAddress, checkout } from "../../types/General";
import { Loader, STORAGE_KEYS, getFromStorage, showError, showToast } from "../../constants";
import { useCartPayNowMutation, useLazyGetCheckoutQuery } from "../../services/cart";
import { useLocation } from "react-router-dom";
import { STRIPE_TEST_KEY } from "../../constants/url";
import { useLazyGetCardsApiQuery } from "../../services/auth";
import { log } from "console";

type checkoutResponse = {
  grandtotal: string;
  products: checkout[];
  shipping: string;
  subtotal: string;
  status: number;
  similarproducts: HerbalProduct[];
}

type prod = {
  id: number,
  name: string,
  price: number,
  quantity: number
}

export const Checkout = () => {
  const location = useLocation();
  const { state } = location
  console.log(state, "STATE")
  const navigate = useNavigate();
  const [getAddress] = useLazyGetAllAddressQuery();
  const userId = getFromStorage(STORAGE_KEYS.userId);
  const [addressData, setAddressData] = useState<UserAddress[]>([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getChekout] = useLazyGetCheckoutQuery();
  const [checkOutData, setCheckOutData] = useState<checkoutResponse>();
  const [addressModal, setAddressModal] = useState(false);
  const [getAllCards] = useLazyGetCardsApiQuery();
  const [cards, setCards] = useState<CardData[]>([]);
  const [selectedCard, setSelectedCard] = useState<any>();
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [selectedAddId, setSelectedAddId] = useState<any>();
  const [payNow] = useCartPayNowMutation();

  console.log(selectedCard, "selectedCard");
  console.log(selectedAddress, "selectedAddress");
  console.log(selectedAddId, "selectedAddId");



  const handleCloseModal = () => {
    setOpen(false);
  };

  const fetchPayNow = async () => {
    setIsLoading(true);
    const body = {
      product: checkOutData?.products,
      shipping_charges: state?.shippingFee,
      amount: Number(checkOutData?.grandtotal),
      delivery_address: selectedAddress,
      delivery_address_id: selectedAddId,
      card_id: selectedCard?.id,
    }
    console.log(body, "body for payments orders");

    if (!selectedAddId) {
      setIsLoading(false);
      showError("Please select an address")
      return;
    }


    if (!selectedCard) {
      setIsLoading(false);
      showError("Please select a card")
      return;
    }

    try {
      const res = await payNow(body).unwrap();
      setIsLoading(false);
      if (res?.status === 200) {
        showToast("Order placed successfuly")
        navigate("/payment-done", { replace: true })
      }

    } catch (errors: any) {
      setIsLoading(false);
      showError(errors?.error)
    }
  }



  const cardImage = (cardType: string) => {
    switch (cardType?.toLowerCase()) {
      case "Visa":
        return "/static/images/visa.png";
      case "electron":
        return "/static/images/electron.png";
      case "maestro":
        return "/static/images/maestro.png";
      case "dankort":
        return "/static/images/dankort.png";
      case "interpayment":
        return "/static/images/interpayment.png";
      case "unionpay":
        return "/static/images/unionpay.png";
      case "mastercard":
        return "/static/images/mastercard.png";
      case "american express":
        return "/static/images/amex.png";
      case "diners club":
        return "/static/images/dinners.png";
      case "discover":
        return "/static/images/discover.png";
      case "jcb":
        return "/static/images/jcb.png";
      default:
        return "/static/images/visa.png";
    }
  };


  const fetchAllCards = async () => {
    try {
      const res = await getAllCards({}).unwrap();
      if (res?.status === 200) {
        setCards(res?.data?.data)
      }
    } catch (errors: any) {
      showError(errors?.error)
    }
  }

  const getAllAddress = async () => {
    setIsLoading(true);
    try {
      const res = await getAddress({ user_id: userId }).unwrap();
      if (res?.status === 200) {
        setAddressData(res?.addresses);
        setIsLoading(false);
      }
    } catch (error: any) {
      showError(error?.errors || "");
    }
  };

  const fetchCheckoutData = async () => {
    try {
      const res = await getChekout({ subTotal: state?.subTotal, shipping: state?.shippingFee, grandtotal: state?.grandTotal }).unwrap();
      if (res?.status === 200) {
        setCheckOutData(res);
      }
    } catch (error: any) {
      showError(error?.errors || "");
    }
  };




  useEffect(() => {
    getAllAddress();
    fetchCheckoutData();
    fetchAllCards();
  }, []);


  const handleCloseAddressModal = () => {
    setAddressModal(false);
  };

  const fun = async () => {
    return "";
  }


  useEffect(() => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        (window as any)["Stripe"].setPublishableKey(STRIPE_TEST_KEY);
      };
      window.document.body.appendChild(s);
    }
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <main className="content checkout_page">
        <section className="site_breadcrum">
          <div className="conta_iner">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a onClick={() => navigate("/my-cart")}>My Cart</a>
              </li>
              <li>My Checkout</li>
            </ul>
          </div>
        </section>

        <section className="checkout_sc ub_spc">
          <div className="conta_iner">
            <div className="gap_p">
              <div className="left_s">
                <h2>Billing Details</h2>
                <div className="checkout_address"
                >
                  <div className="d_flex">
                    <h3>Address</h3>
                    <a
                      href="javascript:void(0)"
                      onClick={() => setAddressModal(true)}
                      data-bs-toggle="modal"
                      data-bs-target="#addAddressModal"
                    >
                      Add
                    </a>
                  </div>
                  <div className="scrollable-similar"
                    style={{ padding: "2px" }}>
                    {addressData?.map((item) => {
                      return (
                        <CheckoutAddress
                          id={item?.id}
                          setSelectedAddId={setSelectedAddId}
                          setSelectedAddress={setSelectedAddress}
                          user_name={(item?.first_name) + " " + (item?.last_name ? item?.last_name : "" )}
                          user_address={item?.address}
                          phone={item?.phone}
                          zipCode={item?.zipcode}
                        />
                      );
                    })}
                  </div>
                </div>

                <h3>Online Payments</h3>
                <div className="payment_list
                  scrollable-similar"
                  style={{ padding: "2px" }}>
                  {cards?.map((item) => {
                    return (
                      <>
                        <label>
                          <figure>
                            <img src={cardImage(item?.brand)} alt="img" />
                          </figure>
                          <span>
                            <strong>•••• •••• •••• {item?.last4}</strong>
                            {item?.brand} - {item?.exp_month}/{item?.exp_year}
                          </span>
                          <input type="radio" name="payment" value="payment1" onClick={() => setSelectedCard(item)} />
                        </label>
                      </>
                    );
                  })}
                  <a
                    href="javascript:void(0)"
                    onClick={() => setOpen(true)}
                    className="add_payment"
                  >
                    <span>
                      <AddIcon />
                    </span>
                    <p className="c_primary">Add New Card</p>
                    <p>Save and Pay via Cards.</p>
                    <img src="static/images/payment_icon.svg" alt="Icon" />
                  </a>
                </div>
              </div>

              <div className="right_s">
                <h2>Your Order</h2>
                <div className="checkout_product_list">
                  {checkOutData?.products?.map((item) => {
                    return (
                      <CheckoutSideItems
                        // key={item?.id}
                        product_id={Number(item?.id)}
                        product_image={item?.image}
                        product_name={item?.name}
                        product_price={item?.price}
                        product_quantity={String(item?.quantity)}
                      />
                    );
                  })}
                </div>
                <div className="sub_total w_100">
                  <p>
                    Subtotal <strong>${checkOutData?.subtotal}</strong>
                  </p>
                  <p>
                    Shipping <strong>${checkOutData?.shipping}</strong>
                  </p>
                  <hr />
                  <p>
                    Grand Total <strong>${checkOutData?.grandtotal}</strong>
                  </p>
                  <div className="btn_flex">
                    <a
                      onClick={() => fetchPayNow()}
                      className="btnn btn_xsm btn_primary br w_100"
                    >
                      Pay Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {checkOutData?.similarproducts?.length ? (
              <div className="products_sc hd_3">
                <h2 className="fw_med">Similar Products</h2>
                <div className="product_listing">
                  <div className="gap_m">
                    {checkOutData?.similarproducts?.map((item) => {
                      return (
                        <SimilarProductCard
                          fetchData={fun}
                          outlet_id={item?.outlet_id}
                          product_id={Number(item?.id)}
                          product_image={item?.productImg}
                          product_name={item?.name}
                          product_desc={item?.about}
                          product_price={String(item?.price)}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : ("")}
          </div>
        </section>
      </main>
      <AddCardModal open={open} onClose={handleCloseModal} setOpen={setOpen} fetchAllCards={fetchAllCards} />
      <AddressModal
        open={addressModal}
        setOpen={setAddressModal}
        onClose={handleCloseAddressModal}
        getAllAddress={getAllAddress}
        addressById={""}
        isAdd={true}
      />
    </Layout >
  );
};
