// export const API_URL = "http://112.196.43.19:8000/api/"; //development

// export const API_URL = "https://mendheal.appgrowthcompany.com/api/"; //staging

// export const API_URL = "https://mendhealdev.appgrowthcompany.com/api/";
export const API_URL = "https://admin.camlyhealth.com/api/";
export const STRIPE_TEST_KEY = "pk_test_51P1z4W02vNLRfgZvqdWMqB8qObPpogdVPWwWKeTcyYkUyXMnMuT2g6genV5XKEC3wcAi3mXq5gBXnnPFlM3GuBvO00UEhsMMaZ";
export const ACCESS_KEY = "AKIAYS2NRO4XZCAHAP5Y";
export const SECRET_ACCESS_KEY = "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"

export const END_POINTS = {
  signUp: "generate-otp",
  test: "test",
  testUser: "test-user",
  register: "users",
  profileSetup: "profile-setup",
  login: "login",
  forgotPassword: "forget-password",
  resetPassword: "reset-password",
  changePassword: "change-password",
  verifyOtp: "verify-otp",

  //profile
  getProfile: "get-profile",
  addAddress: "add-addresses",
  userAddress: "user-addresses",
  upload: "uploadFileAws",
  appointmentHistory: "appointment-history",

  //main
  getDoctors: "get-all-doctors-list",
  updateEmail: "update-email",
  getDoctorById: "get-doctor-details",
  addAppoinment: "add-appointment",
  appointmentDetails: "appointment-details",

  //rating
  addReview: "add-review",
  getReview: "get-review",
  updateReview: "update-review",

  //store
  getAllStores: "get-all-stores",
  getStoreDetails: "get-store-details",
  productDetails: "product-details",

  //filter
  doctorExperience: "doctor-experience",

  //my cart 
  cartItems: "cart-items",
  addToCart: "add-to-cart",
  updateQuantity: "update-quantity",
  deleteItems: "delete-items",
  checkout: "checkout",

  //payment
  paymentToken: "payment-token",
  payNow: "pay-now",
  getCards: "get-cards",
  deleteCard: "delete-card",

  //cms
  cms: "cms",
  payCheckoutOrder: "pay-checkout-order",
  getInvoice: "get-invoice",
  orderHistory: "order-history",
  doctorsFaqs: "doctors-faqs",
  userFaq: "users-faqs",

  //invoice
  downloadInvoice: "download-appointment-invoice",
  downloadOrderInvoice: "download-order-invoice",
  deleteCart: "delete-cart",
  allCategories: "all-categories",
  allProducts: "all-products",

  //footer
  socialLinks:"social-links",
  addSubscriber:"add-subscriber",

};
