import React, { Dispatch, SetStateAction } from 'react';
import { Modal, Button, Box } from '@mui/material';
import { generateResponsiveStyle } from '../utils/authModalStyle';
import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment';
import { STORAGE_KEYS, getFromStorage } from '../constants';

interface AppointmentModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>
    appointMentData: any;
}

export default function AppointmentModal({ open, setOpen, appointMentData }: AppointmentModalProps) {

    const dob = appointMentData?.user?.DOB;
    let age = 0;

    if (dob) {
        const dobDate = moment(dob);
        const now = moment();
        age = now.diff(dobDate, 'years');
    }

    const getStatusText = (status: any) => {
        if (status === "1") {
            return "Approved";
        } else if (status === "2") {
            return "Pending";
        } else if (status === "0") {
            return "Rejected";
        } else {
            return "Unknown";
        }
    };


    function MyComponent(dateString: string) {
        const utcDate = moment.parseZone(dateString);
        const localDate = utcDate.local();
        const formattedDate = localDate.format('dddd, MMMM Do, YYYY h:mma');
        return formattedDate;
    }
    return (
        <Modal
            className="modal invoice_modal"
            id="appointmentInvoiceModal"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={open}
            onClose={() => setOpen(false)}
        >
            <div className="modal-dialog">
                <div className="modal-body hd_4">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <h2>Appointment Invoice</h2>
                    <div className="invoice_box">
                        <div className="d_flex">
                            <div className="billing_info">
                                <h6>Appointment ID: {appointMentData?.booking_number}</h6>
                                <ul>
                                    <li>Appointment Created Date: <b>{MyComponent(appointMentData?.created_at) || "-"}</b></li>
                                    <li>Appointment date/time: <b>{appointMentData?.appointment_date_time + " - " + moment(appointMentData?.slot_end_time, "HH:mm:ss").format("hh:mm A") || "-"}</b></li>
                                </ul>
                            </div>
                            <div className="billing_info">
                                <h6>Status: <span className="c_primary">{getStatusText(appointMentData?.status) || "-"}</span></h6>
                            </div>
                        </div>
                        <div className="d_flex">
                            <div className="billing_info">
                                <h6>Doctor Detail</h6>
                                <p>
                                    Dr.
                                    <br />
                                    {appointMentData?.doctor_name || "-" + " " + appointMentData?.doctor?.last_name || ""}
                                    <br />
                                    {appointMentData?.doctor?.address || "-"}
                                    <br />
                                    {appointMentData?.doctor?.email || "-"}
                                    <br />
                                    {appointMentData?.doctor?.phone_number || "-"}
                                </p>
                            </div>
                            <div className="billing_info">
                                <h6>Patient Detail</h6>
                                <p>
                                    {appointMentData?.patient_name}
                                    <br />
                                    {appointMentData?.user?.gender}
                                    <br />
                                    {age} years old
                                    <br />
                                    {appointMentData?.user?.email}
                                    <br />
                                    {appointMentData?.user?.phone_number}
                                </p>
                            </div>
                        </div>
                        <div className="order_table table-responsive">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th>Speciality</th>
                                        <th>Consultation Type</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{appointMentData?.sub_category}</td>
                                        <td>{appointMentData?.visit_type === "inperson" ? "visit clinic" : "Online consultation"}</td>

                                        <td>$ {Number(appointMentData?.price) - Number(appointMentData?.session_fee)}</td>
                                        <td className="c_primary">{getStatusText(appointMentData?.status) || "-"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="order_total">
                            <p>  Sub-total <strong>$ {Number(appointMentData?.price) - Number(appointMentData?.session_fee)}</strong></p>
                            <p> Session Fee <strong>$ {appointMentData?.session_fee}</strong></p>
                            <hr />
                            <p>Total Cost <strong>$ {appointMentData?.price}</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
