/* eslint-disable jsx-a11y/anchor-is-valid */
import AppointmentModal from "../Modals/appointmentModal";
import { useEffect, useState } from "react";
import RatingModal from "../Modals/RatingModal";
import { getFromStorage, Loader, showError, STORAGE_KEYS } from "../constants";
import { usePostAppointmentHistoryMutation } from "../services/profile";
import { useLazyGetInvoiceQuery } from "../services/main";

type ratingType = {
  patient_id: number;
  doctor_id: number;
  appointment_id: number;
}
export const AppointmentHistoryCard = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openRating, setOpenRating] = useState(false);
  const [historyData, setHistoryData] = useState<any>();
  const [downloadInvoice, { isLoading }] = useLazyGetInvoiceQuery();
  const userId = getFromStorage(STORAGE_KEYS.userId);
  const [getAppointmentHistory] = usePostAppointmentHistoryMutation();
  const [ratingData, setRatingData] = useState<ratingType | any>();

  const handleCloseRating = () => {
    setOpenRating(false);
  };
  const [itemData, setItemData] = useState<any>();

  const getAppointmentHidtory = async () => {
    const body = {
      user_id: userId,
    };

    try {
      const response = await getAppointmentHistory(body).unwrap();

      if (response?.status === 200) {
        setHistoryData(response?.data?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  console.log(historyData, "historyData");

  const fetchInvoice = async (id: any) => {
    try {
      const res: any = await downloadInvoice({ id: id }).unwrap();
      if (res?.status === 200) {
        window.open(res?.url);
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };

  const getStatusText = (status: any) => {
    if (status === "1") {
      return "Approved";
    } else if (status === "2") {
      return "Pending";
    } else if (status === "0") {
      return "Rejected";
    } else {
      return "Unknown";
    }
  };


  useEffect(() => {
    getAppointmentHidtory();
  }, []);

  return (
    <>
      <Loader isLoad={isLoading} />
      {historyData?.length > 0 ? (
        historyData?.map((item: any) => {
          return (
            <div className="orders_box">
              <div className="head">
                <ul>
                  <li>
                    <span>Appointment ID</span>
                    <strong>#{item?.booking_number || "-"}</strong>
                  </li>
                  <li>
                    <span>Appointment date/time</span>
                    <strong>{item?.appointment_date_time || "-"}</strong>
                  </li>
                  <li>
                    <span>Total</span>
                    <strong>${(Number(item?.price).toFixed(2)) || "-"}</strong>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a
                      onClick={() => fetchInvoice(item?.id)}
                      className="btnn btn_primary">
                      Download Invoice
                    </a>
                  </li>
                </ul>
              </div>
              <div className="body">
                <div className="order_table table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Speciality</th>
                        <th>Consultation Type</th>
                        <th>Doctor Name</th>
                        <th>Price</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{item?.sub_category || "-"}</td>
                        <td>{item?.visit_type === "inperson" ? "visit clinic" : "Online consultation" || "-"}</td>
                        <td>
                          Dr.{" "}
                          {`${item?.doctor?.first_name || "-"} ${item?.doctor?.last_name || ""
                            }`}
                        </td>
                        <td>${(Number(item?.price) - Number(item?.session_fee)).toFixed(2) || "-"}</td>
                        <td className="c_primary">
                          {getStatusText(item?.status) || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="head">
                <ul>
                  <li>
                    <span>
                      Status: <strong className={item?.class}>{getStatusText(item?.status) || "-"}</strong>
                    </span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="modal"
                      data-bs-target="#appointmentInvoiceModal"
                      onClick={() => {
                        setItemData(item);
                        setOpen(true);
                      }}
                    >
                      View Invoice
                    </a>
                  </li>
                  {item?.status === "1" && item?.reviews?.length === 0 ? (
                    <li>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenRating(true);
                          setRatingData({
                            patient_id: item?.user_id,
                            doctor_id: item?.doctor_id,
                            appointment_id: item?.id,
                          });
                        }}
                        className="btnn btn_primary br"
                        data-bs-toggle="modal"
                        data-bs-target="#addRatingModal"
                      >
                        Rate this appointment
                      </a>
                    </li>
                  ) : (null)}
                </ul>
              </div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img
              src="/static/images/noapp.png"
              alt="icon"
              style={{ height: "100px", width: "100px", margin: "auto" }}
            />
            <h5>No recent appointments</h5>
          </div>
        </div>
      )}
      <AppointmentModal
        open={open}
        setOpen={setOpen}
        appointMentData={itemData}
      />

      {openRating && ratingData && (
        <RatingModal
          getAppointmentHidtory={getAppointmentHidtory}
          open={openRating}
          setOpen={setOpenRating}
          onClose={handleCloseRating}
          ratingData={ratingData}
        />
      )}
    </>
  );
};
