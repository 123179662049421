/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { SearchBarCom, StoreInfoCard } from "../../components";
import { Layout } from "../../layouts";
import { useNavigate } from "react-router";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, Menu } from "@mui/material";
import { useLazyGetAllStoresQuery } from "../../services/store";
import { Loader, showError } from "../../constants";
import { StoreData, StoreUpdated } from "../../types/General";
import SitePagination from "../../components/Pagination";
import { isValidInput } from "../../utils/validations";
import { useLocation } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import awsconfig from "../../aws-exports"
import { LocationClient, SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";
import CloseIcon from '@mui/icons-material/Close';


Amplify.configure(awsconfig);
export default function StoreListing() {

  const navigate = useNavigate();
  const location = useLocation();
  const [locationState, setLocationState] = useState(location.state);
  const [getStores] = useLazyGetAllStoresQuery();
  const [isLoading,setIsLoading]=useState<boolean>(false)
  const [storeData, setStoreData] = useState<StoreUpdated[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  let totalPages = Math.ceil(totalCount / 10);

  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState<{ latitude: number, longitude: number } | null>(null);

  const fetchSuggestions = async (text: string) => {
    setIsLoading(true)
    const client = new LocationClient({
      region: 'us-east-1',
      credentials: {
        accessKeyId: "AKIAYS2NRO4XZCAHAP5Y",
        secretAccessKey: "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",
      }
    });
    const command = new SearchPlaceIndexForTextCommand({
      IndexName: 'CamlyAddressSearch',
      Text: text,
      MaxResults: 5
    });
    try {
      const response: any = await client.send(command);
      setIsLoading(false)
      setSuggestions(response.Results);
    } catch (error) {
      setIsLoading(false)
      console.error(error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (value.length > 2) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  console.log(selectedLocation, "selectedLocation");

  useEffect(() => {
    setSearchValue(locationState?.search ? locationState?.search : "");
  }, [])

  const handleSuggestionClick = (suggestion: any) => {
    setInputValue(suggestion.Place.Label);
    setSuggestions([]);
    setSelectedLocation({
      latitude: suggestion.Place.Geometry.Point[1],
      longitude: suggestion.Place.Geometry.Point[0]
    });
  };


  const clearInput = () => {
    setInputValue('');
    setSuggestions([]);
  };



  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSortChange = (event: any) => {
    setSortValue(event.target.value);
  };


  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => { setAnchorElUser(event.currentTarget); };
  const handleCloseUserMenu = () => { setAnchorElUser(null); };

  const [anchorElUser2, setAnchorElUser2] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu2 = (event: React.MouseEvent<HTMLElement>) => { setAnchorElUser2(event.currentTarget); };
  const handleCloseUserMenu2 = () => { setAnchorElUser2(null); };

  const [anchorElUser3, setAnchorElUser3] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu3 = (event: React.MouseEvent<HTMLElement>) => { setAnchorElUser3(event.currentTarget); };
  const handleCloseUserMenu3 = () => { setAnchorElUser3(null); };

  const [anchorElUser4, setAnchorElUser4] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu4 = (event: React.MouseEvent<HTMLElement>) => { setAnchorElUser4(event.currentTarget); };
  const handleCloseUserMenu4 = () => { setAnchorElUser4(null); };

  const fetchStores = async () => {
    setIsLoading(true)
    try {
      const res = await getStores({
        search: locationState?.search ? locationState?.search : debouncedSearchTerm ? debouncedSearchTerm : "all",
        page: page,
        sort: sortValue,
        latitude: locationState?.latitude ? locationState?.latitude : selectedLocation?.latitude ? selectedLocation?.latitude : "",
        longitude: locationState?.longitude ? locationState?.longitude : selectedLocation?.longitude ? selectedLocation?.longitude : "",
      }).unwrap();
      setIsLoading(false)
      if (res?.status === 200) {
        setStoreData(res?.stores?.data || []);
        setTotalCount(res?.stores?.total || 0);
      }
    } catch (error: any) {
      setIsLoading(false)
      showError(error);
    }
  }
  console.log(locationState, "locationState for store");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchValue);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);


  useEffect(() => {
    fetchStores();
  }, [page, debouncedSearchTerm, sortValue, selectedLocation, locationState])

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="content doctorListing_page">
        <section className="site_breadcrum">
          <div className="conta_iner">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>Store Listing</li>
            </ul>
          </div>
        </section>

        <section className="grid_sc ub_spc">
          <div className="conta_iner">
            <div className="gap_p">
              <div className="left_s">
                <form action="" className="search_form">
                  <div className="control_group">
                    <SearchBarCom
                      className="icon_search"
                      searchTerm={searchValue}
                      setDebouncedSearchTerm={setDebouncedSearchTerm}
                      value={searchValue}
                      onCross={() => { setSearchValue(""); setLocationState({ search: "" }) }}
                      placeholder="Store"
                      onChange={(val: any) => {
                        if (isValidInput(val.target.value)) {
                          setSearchValue(val.target.value);
                        }
                      }}
                    />
                  </div>
                  <hr />
                  <div className="control_group">
                    <div className="input_with_clear">
                      <input
                        className="icon_location"
                        type="text"
                        placeholder="Location"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                      {inputValue && (
                        <button className="clear_button" onClick={clearInput}><CloseIcon /></button>
                      )}
                    </div>
                    {inputValue && suggestions.length > 0 && (
                      <ul className="suggestions_list">
                        {suggestions.map((suggestion: any, index: number) => (
                          <li
                            style={{ marginBottom: "1px" }}
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                            className="suggestion-item"
                          >
                            {suggestion.Place.Label}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {/* <button type="button" className="btnn btn_primary">
                    <img src="static/images/search_icon_small.svg" alt="Icon" />
                  </button> */}
                </form>
                <div className="filter_sc">
                  <div className="dropdown">
                    <Box className={anchorElUser ? "dropdown-toggle active" : "dropdown-toggle"} onClick={handleOpenUserMenu}>Nearby</Box>
                    <Menu
                      className="filter_dropdown_menu"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <h6>Nearby</h6>
                      <form>
                        <RadioGroup
                          className="filter_list"
                          aria-labelledby="demo-nearby-label"
                          name="nearby"
                        >
                          <FormControlLabel className="checkbox_label" value="mile1" control={<Radio />} label="1 mile" />
                          <FormControlLabel className="checkbox_label" value="mile2" control={<Radio />} label="5 miles" />
                          <FormControlLabel className="checkbox_label" value="mile3" control={<Radio />} label="10 miles" />
                          <FormControlLabel className="checkbox_label" value="mile4" control={<Radio />} label="25 miles" />
                          <FormControlLabel className="checkbox_label" value="mile5" control={<Radio />} label="50 miles" />
                        </RadioGroup>
                        <div className="btn_flex">
                          <input type="reset" className="btn_link" value="Clear" />
                          <button type="submit" className="btnn btn_xsm btn_primary">Close</button>
                        </div>
                      </form>
                    </Menu>
                  </div>

                  <div className="dropdown">
                    <Box className={anchorElUser3 ? "dropdown-toggle active" : "dropdown-toggle"}
                      onClick={handleOpenUserMenu3}
                      style={sortValue ? { backgroundColor: "#29AB87" } : {}}
                    >Sort</Box>
                    <Menu
                      className="filter_dropdown_menu"
                      anchorEl={anchorElUser3}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser3)}
                      onClose={handleCloseUserMenu3}
                    >
                      <h6>Sort</h6>
                      {/* <form> */}
                      <RadioGroup
                        className="filter_list"
                        aria-labelledby="demo-sort-label"
                        name="sort"
                        value={sortValue}
                        onChange={handleSortChange}
                      >
                        <FormControlLabel className="checkbox_label" value="asc" control={<Radio />} label="By Ascending" />
                        <FormControlLabel className="checkbox_label" value="desc" control={<Radio />} label="By Descending" />
                      </RadioGroup>
                      <div className="btn_flex">
                        {sortValue ? (
                          <input type="reset" className="btn_link" value="Clear" onClick={() => setSortValue("")} />
                        ) : ("")}
                        <button type="submit" className="btnn btn_xsm btn_primary" onClick={() => handleCloseUserMenu3()}>Close</button>
                      </div>
                      {/* </form> */}
                    </Menu>
                  </div>

                </div>
                <div className="hd_4">
                  <h2 className="mb-0">Result Showing {totalCount || 0} {" "}{totalCount === 1 ? "Outlet" : "Outlets"}</h2>
                </div>
                <div className="box_listing">
                  <div className="gap_p hd_4">
                    <StoreInfoCard storeData={storeData} />
                  </div>
                </div>
              </div>
              <div className="right_s">
                <div className="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13720.683685743084!2d76.709613!3d30.713595!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feef63fffffdd%3A0xe08a861af795e737!2sApptunix%20-%20Mobile%20App%20Development%20Company!5e0!3m2!1sen!2sin!4v1707717234796!5m2!1sen!2sin"></iframe>
                </div>
              </div>
              {storeData?.length ? (
                <SitePagination
                  module={storeData}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : undefined}
            </div>
          </div>

        </section>
      </div>
    </Layout>
  );
}
