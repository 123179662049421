/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { PicPreview, PleaseLoginModal, SameOutletWarning, SimilarProductCard } from "../../components";
import cardData from "../../data/ProductData.json";
import { Layout } from "../../layouts";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router";
import { useLazyGetProductDetailsQuery } from "../../services/store";
import { useParams } from "react-router-dom";
import { ProductDetails, SimilarProducts } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { useAddTocartMutation } from "../../services/cart";
import useAuth from "../../hooks/useAuth";

type ProductDetailsResponse = {
  how_to_use: string[];
  message: string;
  keybenefits: string[];
  productimages: string[];
  product: ProductDetails[];
  similar_products: SimilarProducts;
  status: number;
}


export const ProductDeatils = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useAuth();
  const [nav1, setNav1] = useState<any | null>(null);
  const [nav2, setNav2] = useState<any | null>(null);
  const [src, setSrc] = useState<string>("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const [openImage, setOPenImage] = useState<boolean>(false);
  const [showAdd, setShowAdd] = useState<boolean>(true);
  const sliderRef1 = useRef<any | null>(null);
  const sliderRef2 = useRef<any | null>(null);
  const [getProductDetails, { isLoading }] = useLazyGetProductDetailsQuery();
  const [productData, setProductData] = useState<ProductDetailsResponse | undefined>();
  const [addToCart] = useAddTocartMutation();

  const fetchDetails = async () => {
    const res = await getProductDetails({ id: id || "" }).unwrap();
    try {
      if (res.status === 200) {
        setProductData(res);
      }
    } catch (error: any) {
      showError(error);
    }
  }

  const fun = async () => "";


  const addItems = async () => {
    try {
      const body = {
        product_id: productData?.product[0]?.id || NaN,
        quantity: 1,
        outlet: productData?.product[0]?.outlet_id
      }
      console.log(body, "body for add to cart")
      const res = await addToCart(body).unwrap();
      if (res?.status === 200) {
        showToast("item added to cart")
        setShowAdd(false)
      }
      else if (res?.status === 220) {
        setOpen(true);
      } else {
        showError("Sorry, item is out of stock")
      }
    } catch (errors: any) {
      showError(errors)
    }
  }




  const hasProductImages = productData?.productimages && productData.productimages.length > 1;

  console.log(productData, "productData..................");

  const handleAfterChange = (index: number) => {
    setActiveIndex(index);
    nav2.slickGoTo(index);
  };

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, []);

  useEffect(() => {
    if (id) {
      fetchDetails();
    }
  }, [id])

  console.log(productData, "productData?.productimages");


  return (
    <>
      <Layout>
        <Loader isLoad={isLoading} />
        <main className="content productDetail_page">
          <section className="site_breadcrum">
            <div className="conta_iner">
              <ul>
                <li>
                  <a onClick={() => navigate("/")}>Home</a>
                </li>
                <li>
                  <a onClick={() => navigate("/store-listing")}>
                    Store Listing
                  </a>
                </li>
                <li>
                  <a onClick={() => navigate(`/store-details/${productData?.product[0]?.outlet_id}`)}>{productData?.product[0]?.merchant?.store_name}</a>
                </li>
                <li>{productData?.product[0]?.name}</li>
              </ul>
            </div>
          </section>

          <section className="productDetail_sc ub_spc">
            <div className="conta_iner">
              <div className="product_info">
                <div className="product_image_sc">
                  <Slider
                    className="product_gallery"
                    afterChange={handleAfterChange}
                    asNavFor={nav2}
                    slidesToShow={1}
                    slidesToScroll={1}
                    ref={(slider) => (sliderRef1.current = slider)}
                    infinite={hasProductImages}
                  >
                    {productData?.productimages?.length
                      ? productData?.productimages?.map((item, index) => (
                        <figure className="product_image" key={index} onClick={() => { setOPenImage(true); setSrc(item) }}>
                          <img
                            src={
                              item ? item :
                              "/images/testimonial_attachment_1.jpg"
                            }
                            alt=""
                          />
                        </figure>
                      ))
                      : undefined}
                  </Slider>
                  <Slider
                    className="product_thumb"
                    asNavFor={nav1}
                    ref={(slider) => (sliderRef2.current = slider)}
                    slidesToShow={4}
                    slidesToScroll={1}
                    swipeToSlide={hasProductImages}
                    focusOnSelect={hasProductImages}
                    infinite={hasProductImages}
                  >
                    {productData?.productimages?.length
                      ? productData?.productimages?.map((item, index) => (
                        <figure className="product_image" key={index}>
                          <img
                            src={
                              item ||
                              "/images/testimonial_attachment_1.jpg"
                            }
                            alt=""
                          />
                        </figure>
                      ))
                      : undefined}
                  </Slider>
                </div>
                <div className="right_info hd_3">
                  <h2>
                    {productData?.product[0]?.name}
                  </h2>
                  <p>{productData?.product[0]?.category?.name}</p>
                  <h3>$ {productData?.product[0]?.price}</h3>
                  <div className="btn_flex">
                    {showAdd ? (
                      <a
                        onClick={() => user ? addItems() : setOpen1(true)}
                        className="btnn btn_primary br"
                      >
                        Add to Cart
                      </a>
                    ) : (
                      <a
                        onClick={() => navigate("/my-cart")}
                        className="btnn btn_primary br"
                      >
                        Go to Cart
                      </a>
                    )}
                    <a
                      onClick={() => user ? (navigate("/product-checkout", { state: productData })) : (setOpen1(true))}
                      className="btnn btn_primary"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="detailed_info">
                <div className="hd_3">
                  <h2 className="fw_med">About Product</h2>
                </div>
                <p>
                  {productData?.product[0]?.about}
                </p>
                <div className="hd_4">
                  <h2 className="fw_med">Key Benefits</h2>
                </div>
                <div>
                  {productData?.keybenefits?.map((item, index) => (
                    <p key={index}>{item.trim()}</p>
                  ))}
                </div>

                <div className="hd_4">
                  <h2 className="fw_med">How to use the product</h2>
                  {productData?.how_to_use?.length ? (
                    productData.how_to_use.map((item, index) => (
                      <p key={index}>
                        {item}
                      </p>
                    ))
                  ) : (
                    <p>No instructions available.</p>
                  )}
                </div>
              </div>
              <hr />
              {productData?.similar_products?.data?.length ? (
                <div className="products_sc hd_3">
                  <h2 className="fw_med">Similar Products</h2>
                  <div className="product_listing">
                    <div className="gap_m">
                      {productData?.similar_products?.data?.map((item) => {
                        return (
                          <SimilarProductCard
                            fetchData={fun}
                            key={item?.id}
                            outlet_id={item?.outlet_id}
                            product_id={Number(item?.id)}
                            product_image={item?.productImg}
                            product_name={item?.name}
                            product_desc={item?.about}
                            product_price={item?.price}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : ("")}

            </div>
          </section>
        </main >
        <SameOutletWarning open={open} setOpen={setOpen} setShowAdd={setShowAdd} body={{
          product_id: productData?.product[0]?.id || NaN,
          quantity: 1,
          outlet: productData?.product[0]?.outlet_id
        }} />
        <PleaseLoginModal open={open1} setOpen={setOpen1} />
        <PicPreview open={openImage} setOpen={setOPenImage} src={src} />
      </Layout >
    </>
  );
};
