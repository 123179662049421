import { END_POINTS } from "../constants/url";
import { Doctors, Experience, Subcategory, UserAddress, WeeklySchedule } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  status: number;
  mesaage: string;
  token: string;
  user: User;
  message: string;
};

type categoryRes = {
  status: number;
  subcategory: Subcategory[];
}

type GetDoctorResponse = {
  user: Doctors;
  status: number;
  mesaage: string;
  rating: number;
  token: string;
  doctors: {
    data: Doctors[];
    total: number;
  };
  operational: WeeklySchedule[];
};

type DoctorParams = {
  page: number;
  search: string;
  experience?: number | null;
  gender?: string | null;
  price?: string | null;
  rating?: string | null;
  morning?: string | null;
  evening?: string | null;
  afternoon?: string | null;
  category: string | null;
  weekend?: string | null;
  type: number;
  latitude: string | null;
  longitude: string | null;
};

type addAppoinmentBody = {
  user_id: any;
  status: number;
  price: number;
  doctor_name: string;
  visit_type: string;
  appointment_date_time: string;
  patient_name: string | undefined;
  doctor_id: number;
  sub_category: string;
  // health_condition_name: string;
  // first_diagnosed_on: string;
};

type AppointmentDetailBody = {
  booking_number: number;
};

type DoctorBody = {
  id: number | undefined | string;
};

type PostAddAddressBody = {
  id: any;
  email: string;
  otp: string;
};

type CommonResponse = {
  status: number;
  message: string;
};

type PostTestBody = {
  email: string;
  full_name: string;
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    GetAllDoctors: builder.query<GetDoctorResponse, DoctorParams>({
      query: ({ page, search, experience, gender, price, rating, morning, afternoon, evening, weekend, type, category, latitude, longitude }) => ({
        url: `${END_POINTS.getDoctors}?search=${search}&page=${page}&experience=${experience}&gender=${gender}&price=${price}&rating=${rating}&morning=${morning}&afternoon=${afternoon}&evening=${evening}&weekend=${weekend}&type=${type}&category=${category}&latitude=${latitude}&longitude=${longitude}`,
        method: "GET",
      }),
    }),

    postChangeEmail: builder.mutation<
      CommonResponseType & { data: any },
      PostAddAddressBody
    >({
      query: (body) => ({
        url: END_POINTS.updateEmail,
        method: "POST",
        body,
      }),
    }),

    getDoctorById: builder.mutation<GetDoctorResponse, DoctorBody>({
      query: (body) => ({
        url: END_POINTS.getDoctorById,
        method: "POST",
        body,
      }),
    }),

    postTestApi: builder.mutation<
      CommonResponseType & { data: any },
      PostTestBody
    >({
      query: (body) => ({
        url: END_POINTS.testUser,
        method: "POST",
        body,
      }),
    }),

    postAddAppointment: builder.mutation<
      CommonResponseType & { data: any },
      addAppoinmentBody
    >({
      query: (body) => ({
        url: END_POINTS.addAppoinment,
        method: "POST",
        body,
      }),
    }),

    postAppointmentDetails: builder.mutation<
      CommonResponseType & { data: any },
      AppointmentDetailBody
    >({
      query: (body) => ({
        url: END_POINTS.appointmentDetails,
        method: "POST",
        body,
      }),
    }),

    getDoctorExpFilter: builder.query<
      Experience, {}>({
        query: () => ({
          url: END_POINTS.doctorExperience,
          method: "GET"
        })
      }),

    // getInvoice:builder.query<
    getInvoice: builder.query<CommonResponse & { data: any }, {
      id: number
    }>({
      query: ({ id }) => ({
        url: `${END_POINTS.downloadInvoice}?id=${id}`,
        method: "GET",
      })
    }),

    //get invoice for orders
    getOrderInvoice: builder.query<CommonResponse & { url: any }, {
      id: number
    }>({
      query: ({ id }) => ({
        url: `${END_POINTS.downloadOrderInvoice}?id=${id}`,
        method: "GET",
      })
    }),

    //categories
    getAllCategories: builder.query<categoryRes, { search: string }>({
      query: ({ search }) => ({
        url: `${END_POINTS.allCategories}?search=${search}`,
        method: "GET",
      }),
    }),

    //footer
    getSocialLinks: builder.query<CommonResponse & { socialLinks: any }, {}>({
      query: () => ({
        url: END_POINTS.socialLinks,
        method: "GET",
      }),
    }),


    postEmail: builder.mutation<
      CommonResponseType & { data: any },
      { body: any }
    >({
      query: (body) => ({
        url: END_POINTS.addSubscriber,
        method: "POST",
        body,
      }),
    }),


  }),
});

export const {
  useLazyGetAllDoctorsQuery,
  usePostChangeEmailMutation,
  useGetDoctorByIdMutation,
  usePostTestApiMutation,
  usePostAddAppointmentMutation,
  usePostAppointmentDetailsMutation,
  useLazyGetDoctorExpFilterQuery,
  useLazyGetInvoiceQuery,
  useLazyGetOrderInvoiceQuery,
  useLazyGetAllCategoriesQuery,
  useLazyGetSocialLinksQuery,
  usePostEmailMutation
} = mainApi;
