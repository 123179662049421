import React, { useEffect } from "react";
import "./App.css";
import Routing from "./routes";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS, getFromStorage } from "./constants";
import { firebaseCloudMessaging } from "./utils/firebase";
import { toast } from "react-toastify";


function App() {

  const navigate = useNavigate();

  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();

    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          return toast(
            <div style={{ height: "100%" }}>
              <div
                style={{ color: "#820000" }}
                onClick={() => navigate("/recieved-notifications")}
              >
                {event?.data?.data?.default}
              </div>
              <div
                style={{ color: "#1d1d1d", paddingTop: "10px" }}
                onClick={() => navigate("/recieved-notifications")}
              >
                {event?.data?.notification?.body}
              </div>
            </div>
          );
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          notificationPerm.onchange = function () {
            if (notificationPerm.state === "granted") {
              setToken();
            }
          };
        });
    }
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

  return (
    <div className="App">        
      <Routing />
    </div>
  );
}

export default App;
