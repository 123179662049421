import { Modal, Box } from '@mui/material'
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { generateResponsiveStyle } from '../utils/authModalStyle';
import { Order } from '../types/General';
import { Console } from 'console';



interface InvoiceModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>
    item: Order | undefined;
}

export default function OrderInvoice({ open, onClose, setOpen, item }: InvoiceModalProps) {
    const style = generateResponsiveStyle()
    console.log(item, "order invoice data")

    return (
        <Modal
            className="modal invoice_modal"
            id="orderInvoiceModal"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={open}
            onClose={onClose}
        >
            <div className="modal-dialog">
                <div className="modal-body hd_4">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <h2>Order Invoice</h2>
                    <div className="invoice_box">
                        <div className="d_flex">
                            <div className="billing_info">
                                <h6>Billing Address</h6>
                                <p>
                                    {/* {item?.} */}
                                    <br />
                                    {item?.name || ""}
                                    <br />
                                    {item?.delivery_address}
                                    <br />
                                    {item?.user_email}
                                    <br />
                                    {item?.user_phone_number}
                                </p>
                            </div>
                            <div className="billing_info">
                                <h6>Order ID: # {item?.order_number}</h6>
                                <ul>
                                    <li>Order Date: <b>{item?.order_date_display}</b></li>
                                    <li>Deliverd Date: <b>{item?.delivery_date_display}</b></li>
                                </ul>
                            </div>
                        </div>
                        <div className="order_table table-responsive">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        item?.sold_product?.map((temp) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <figure className="order_image">
                                                            <img src={temp?.image} alt="icon" />
                                                            <figcaption>{temp?.product_name}</figcaption>
                                                        </figure>
                                                    </td>
                                                    <td>${temp?.price_per_product}</td>
                                                    <td>{temp?.quantity}</td>
                                                    <td>${(temp?.quantity * Number(temp?.price_per_product)).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                        <div className="order_total">
                            <p>Subtotal <strong>${(Number(item?.total_amount) - Number(item?.shipping_charges)).toFixed(2)}</strong></p>
                            <p>Shipping <strong>${item?.shipping_charges}</strong></p>
                            <hr />
                            <p>Grand Total <strong>${item?.total_amount}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
