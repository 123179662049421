/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddTocartMutation } from "../services/cart";
import { Loader, showError, showToast } from "../constants";
import { add } from "date-fns";
import SameOutletWarning from "./addToCartWarning";
import useAuth from "../hooks/useAuth";
import PleaseLoginModal from "./pleaseLogin";

type SimilarProductType = {
  outlet_id: number;
  product_id: number;
  product_image: string;
  product_desc: string | null;
  product_name: string;
  product_price: string;
  fetchData: () => void;
};


const SimilarProductCard = ({
  product_id,
  product_image,
  product_desc,
  product_name,
  product_price,
  fetchData,
  outlet_id,
}: SimilarProductType) => {
  const [addToCart, { isLoading }] = useAddTocartMutation();
  const [open, setOpen] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const user = useAuth();
  const [showAdd, setShowAdd] = useState<boolean>(true);
  const navigate = useNavigate();

  const truncateText = (text: string, wordLimit: number) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const addItems = async () => {
    try {
      const body = {
        product_id: product_id,
        quantity: 1,
        outlet: outlet_id,
      }
      console.log(body, "body for add to cart")
      const res = await addToCart(body).unwrap();
      if (res?.status === 200) {
        showToast("Item added to cart");
        setShowAdd(false)
        fetchData();
      }
      else if (res?.status === 220) {
        setOpen(true);
      }
      else {
        showError("Sorry, item is out of stock")
      }
    } catch (errors: any) {
      showError(errors)
    }
  }


  return (
    <>
      <div className="product_single">
        <Loader isLoad={isLoading} />
        <a onClick={() => { navigate(`/product-details/${product_id}`); window.scrollTo(0, 0); }}>
          <figure>
            {/* <img src="static/images/product_attachment_01.jpg" alt="Image" /> */}
            <img src={product_image} alt="Image" />
          </figure>
        </a>
        <h3>
          <a onClick={() => { navigate(`/product-details/${product_id}`); window.scrollTo(0, 0); }}> {truncateText(product_name, 2)}</a>
        </h3>
        <p onClick={() => { navigate(`/product-details/${product_id}`); window.scrollTo(0, 0); }}>{truncateText(product_desc || "", 9)}</p>
        <div className="btn_flex">
          {showAdd ? (
            <Button onClick={() => user ? addItems() : setOpen1(true)} className="btnn btn_primary">
              Add to Cart
            </Button>
          ) : (
            <Button onClick={() => navigate("/my-cart")} className="btnn btn_primary">
              Go to Cart
            </Button>
          )}
          <p>{"$" + product_price}</p>
        </div>
      </div >
      <SameOutletWarning open={open} setOpen={setOpen} setShowAdd={setShowAdd} body={
        {
          product_id: product_id,
          quantity: 1,
          outlet: outlet_id,
        }
      } />
      <PleaseLoginModal open={open1} setOpen={setOpen1} />
    </>
  );
};

export default SimilarProductCard;
