/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AddCardModal from "../Modals/addCardModal";
import AddIcon from "@mui/icons-material/Add";
import { useLazyGetCardsApiQuery, usePayNowMutation } from "../services/auth";
import { Loader, showError, showToast } from "../constants";
import { CardData } from "../types/General";
import { Session } from "inspector";
import { Button } from "@mui/material";
import { useCartPayNowMutation } from "../services/cart";
import { STRIPE_TEST_KEY } from "../constants/url";

type paymentProps = {
  appointmentData: any;
  price: string;
  from: string;
};

export function PaymentDetailsCard({ appointmentData, price, from }: paymentProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getAllCards] = useLazyGetCardsApiQuery();
  const [cards, setCards] = useState<CardData[]>([]);
  const [selected, setSelected] = useState<any>();
  const [open, setOpen] = useState(false);
  const handleCloseModal = () => {
    setOpen(false);
  };
  const [payNow] = usePayNowMutation();



  const cardImage = (cardType: string) => {
    switch (cardType?.toLowerCase()) {
      case "Visa":
        return "/static/images/visa.png";
      case "electron":
        return "/static/images/electron.png";
      case "maestro":
        return "/static/images/maestro.png";
      case "dankort":
        return "/static/images/dankort.png";
      case "interpayment":
        return "/static/images/interpayment.png";
      case "unionpay":
        return "/static/images/unionpay.png";
      case "mastercard":
        return "/static/images/mastercard.png";
      case "american express":
        return "/static/images/amex.png";
      case "diners club":
        return "/static/images/dinners.png";
      case "discover":
        return "/static/images/discover.png";
      case "jcb":
        return "/static/images/jcb.png";
      default:
        return "/static/images/visa.png";
    }
  };

  const fetchPayNow = async () => {
    setIsLoading(true);
    try {
      const body = {
        appointment_id: appointmentData?.id,
        card_id: selected?.id,
        slot_id: appointmentData?.slot_id,
      }
      console.log(body, "body of pay now");

      if (!selected?.id) {
        showError("Please select a card")
        setIsLoading(false)
        return;
      }

      const res = await payNow(body).unwrap();
      if (res?.status === 200) {
        setIsLoading(false)
        showToast("Booking created successfully");
        navigate("/payment-done", { replace: true })
      }
    } catch (error: any) {
      setIsLoading(false);
      console.log(error?.errors);

    }
  }

  const fetchAllCards = async () => {
    setIsLoading(true)
    try {
      const res = await getAllCards({}).unwrap();
      if (res?.status === 200) {
        setIsLoading(false)
        setCards(res?.data?.data)
      }
    } catch (errors: any) {
      setIsLoading(false)
      showError(errors?.error)
    }
  }

  useEffect(() => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        (window as any)["Stripe"].setPublishableKey(STRIPE_TEST_KEY);
      };
      window.document.body.appendChild(s);
    }
  }, []);

  useEffect(() => {
    fetchAllCards();
  }, []);


  return (
    <div className="payment_box">
      <Loader isLoad={isLoading} />
      <h3>Payment Detail</h3>
      <div className="p_group">
        <p>
          {from === "booking" ? "Session fee" : "Shipping fee"} <strong>${price || "0"}</strong>
        </p>
        <p>
          Service charges <strong>${appointmentData?.price - Number(price) || "0"}</strong>
        </p>
        <p>
          Total Cost <strong>${appointmentData?.price || "0"}</strong>
        </p>
      </div>
      <h3>Online Payments</h3>
      <div className="payment_list">
        {
          cards?.length ?
            (
              cards?.map((item) => {
                return (
                  <label>
                    {/* <a href="javascript:void(0)" onClick={() => setOpen(true)}></a> */}
                    <figure>
                      <img src={cardImage(item?.brand)} alt="img" />
                    </figure>
                    <span>
                      <strong>•••• •••• •••• {item?.last4}</strong>
                      {item?.brand} - {item?.exp_month}/{item?.exp_year}
                    </span>
                    <input type="radio" name="payment" value="payment1" onClick={() => setSelected(item)} />
                  </label>
                )
              })
            ) : (
              ""
            )
        }
        <a
          href="javascript:void(0)"
          className="add_payment"
          onClick={() => setOpen(true)}
        >
          <span>
            <AddIcon />
          </span>
          <p className="c_primary">Add New Card</p>
          <p>Save and Pay via Cards.</p>
          <img src="static/images/payment_icon.svg" alt="Icon" />
        </a>
      </div>

      <div className="btn_flex">
        <Button
          // href="javascript:void(0)"
          // onClick={() => navigate("/payment-done")}
          className="btnn btn_primary"
          onClick={() => {
            fetchPayNow();
          }}
        >
          Pay Now
        </Button>
      </div>
      <AddCardModal open={open} onClose={handleCloseModal} setOpen={setOpen} fetchAllCards={fetchAllCards} />
    </div>
  );
}
