import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AcupunctureCard, SearchBarCom } from "../../components";
import { Layout } from "../../layouts";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Menu } from "@mui/material";
import { useLazyGetAllCategoriesQuery, useLazyGetAllDoctorsQuery, useLazyGetDoctorExpFilterQuery } from "../../services/main";
import { Loader, showError } from "../../constants";
import SitePagination from "../../components/Pagination";
import { Doctors, Exp, Subcategory } from "../../types/General";
import { useLocation } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

import { LocationClient, SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";
import { Offset } from "mapbox-gl";
import { ACCESS_KEY, SECRET_ACCESS_KEY } from "../../constants/url";
import {
  Flex,
  Heading,
  View
} from '@aws-amplify/ui-react';
import MapListingView from "../../components/MapListingView";


export default function DocterListing() {
  const navigate = useNavigate();
  const location = useLocation();
  const [getExperience] = useLazyGetDoctorExpFilterQuery();
  const [show, setShow] = useState<boolean>(false);
  const [expData, setExpData] = useState<Exp[]>();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getAllDoctorsQuery] = useLazyGetAllDoctorsQuery();
  const [doctorsData, setDoctorsData] = useState<Doctors[]>([]);
  const [locationState, setLocationState] = useState(location.state);
  const [genderValue, setGenderValue] = useState(null);
  const [experienceValue, setExperienceValue] = useState(null);
  const [priceValue, setPriceValue] = useState(null);
  const [ratingValue, setRatingValue] = useState(null);
  const [morningValue, setMorningValue] = useState(null);
  const [afterValue, setAfterValue] = useState(null);
  const [eveningValue, setEveningValue] = useState(null);
  const [weekendValue, setWeekendValue] = useState(null);
  const [currentDate, setCurrentDate] = useState<any>(new Date());
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [getCategories] = useLazyGetAllCategoriesQuery();
  const [categories, setCategories] = useState<Subcategory[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<{ latitude: string, longitude: string } | null>(null);



  const fetchSuggestions = async (text: string) => {
    setIsLoading(true)
    const client = new LocationClient({
      region: 'us-east-1',
      credentials: {
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
      }
    });
    const command = new SearchPlaceIndexForTextCommand({
      IndexName: 'CamlyAddressSearch',
      Text: text,
      MaxResults: 5
    });
    try {
      const response: any = await client.send(command);
      setIsLoading(false)
      setSuggestions(response.Results);
    } catch (error) {
      setIsLoading(false)
      console.error(error);
    }
  };


  const handleSuggestionClick = (suggestion: any) => {
    setInputValue(suggestion.Place.Label);
    setSuggestions([]);
    setSelectedLocation({
      latitude: suggestion.Place.Geometry.Point[1],
      longitude: suggestion.Place.Geometry.Point[0]
    });
  };


  const clearInput = () => {
    setInputValue('');
    setSuggestions([]);
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (value.length > 2) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    setSearchValue(locationState?.search ? locationState?.search : "");
  }, [])

  const fetchExp = async () => {
    try {
      const res = await getExperience({}).unwrap();
      if (res?.status === 200) {
        setExpData(res?.experience);
      }
    } catch (error: any) {
      showError(error)
    }
  }

  const handleGenderChange = (event: any) => {
    setGenderValue(event.target.value);
  };


  const handleExpChange = (event: any) => {
    setExperienceValue(event.target.value);
  };


  const handlePriceChange = (event: any) => {
    setPriceValue(event.target.value);
  };


  const handleRatingChange = (event: any) => {
    setRatingValue(event.target.value);
  };


  const handleMorningChange = (event: any) => {
    setMorningValue(event.target.value);
  };


  const handleAfterChange = (event: any) => {
    setAfterValue(event.target.value);
  };


  const handleEveningChange = (event: any) => {
    setEveningValue(event.target.value);
  };


  const handleWeekendChange = (event: any) => {
    setWeekendValue(event.target.value);
  };




  console.log(experienceValue, "expValue.....")
  const formatDate = (date: any) => {
    // Format the date in 'Monday, Jan 16, 2024' format
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  // Function to handle click on the right arrow (next day)
  const handleNextDayClick = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1); // Add 1 day
    setCurrentDate(nextDate);
  };


  // Function to handle click on the left arrow (previous day)
  const handlePrevDayClick = () => {
    const prevDate = new Date(currentDate);
    prevDate.setDate(prevDate.getDate() - 1); // Subtract 1 day
    setCurrentDate(prevDate);
  };


  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllDoctors = async () => {
    setIsLoading(true)
    try {
      const res = await getAllDoctorsQuery({
        page: page,
        search: locationState?.search ? locationState?.search.split(' ')[0] : debouncedSearchTerm ? debouncedSearchTerm : "all",
        experience: experienceValue,
        gender: genderValue,
        price: priceValue,
        rating: ratingValue,
        morning: morningValue,
        afternoon: afterValue,
        evening: eveningValue,
        weekend: weekendValue,
        type: locationState?.value,
        category: locationState?.category ? locationState?.category : null,
        longitude: locationState?.longitude ? locationState?.longitude : selectedLocation?.longitude ? selectedLocation?.longitude : null,
        latitude: locationState?.latitude ? locationState?.latitude : selectedLocation?.latitude ? selectedLocation?.latitude : null,
      }).unwrap();

      setIsLoading(false)
      if (res?.status === 200) {
        setDoctorsData(res?.doctors?.data);
        setTotalCount(res?.doctors?.total);
      }
    } catch (error: any) {
      setIsLoading(false)
      if (error?.data?.message) {
        showError(error?.data?.message || "");
      }
    }
  };

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  console.log(anchorElUser, "anchorElUser...")
  const [anchorElUser2, setAnchorElUser2] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser2(event.currentTarget);
  };
  const handleCloseUserMenu2 = () => {
    setAnchorElUser2(null);
  };

  const [anchorElUser3, setAnchorElUser3] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu3 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser3(event.currentTarget);
  };
  const handleCloseUserMenu3 = () => {
    setAnchorElUser3(null);
  };

  const [anchorElUser4, setAnchorElUser4] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu4 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser4(event.currentTarget);
  };
  const handleCloseUserMenu4 = () => {
    setAnchorElUser4(null);
  };

  const [anchorElUser5, setAnchorElUser5] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu5 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser5(event.currentTarget);
  };
  const handleCloseUserMenu5 = () => {
    setAnchorElUser5(null);
  };

  const [anchorElUser6, setAnchorElUser6] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu6 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser6(event.currentTarget);
  };
  const handleCloseUserMenu6 = () => {
    setAnchorElUser6(null);
  };

  const [anchorElUser7, setAnchorElUser7] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu7 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser7(event.currentTarget);
  };
  const handleCloseUserMenu7 = () => {
    setAnchorElUser7(null);
  };

  const [anchorElUser8, setAnchorElUser8] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu8 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser8(event.currentTarget);
  };
  const handleCloseUserMenu8 = () => {
    setAnchorElUser8(null);
  };

  useEffect(() => {
    fetchExp();
  }, [])

  console.log(locationState, "locationState?.value");


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchValue);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    getAllDoctors();
  }, [page, locationState?.value, locationState?.category, locationState?.search,
    debouncedSearchTerm, currentDate, experienceValue, genderValue, priceValue, ratingValue, morningValue,
    afterValue, eveningValue, weekendValue, selectedLocation]);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="content doctorListing_page">
        <section className="site_breadcrum">
          <div className="conta_iner">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>Doctor Listing</li>
            </ul>
          </div>
        </section>

        <section className="grid_sc ub_spc">
          <div className="conta_iner">
            <div className="gap_p">
              <div className="left_s">
                <form action="" className="search_form">
                  <div className="control_group">
                    <input
                      className="icon_search"
                      type="text"
                      placeholder="Condition, Procedure, Doctor"
                      onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                        { setCategories([]); setSearchValue(val.target.value); setShow(true) }
                      }}
                      value={searchValue}
                    />
                    {searchValue && (
                      <button className="clear_button" onClick={() => { setSearchValue(""); setLocationState({ search: "", value: null }) }}><CloseIcon /></button>
                    )}
                  </div>
                  {/* {show ? (
                    <div>
                      <ul className="suggestions_list" style={{
                        width: "47%", padding: "8px 16px",
                        borderBottom: "1px solid #ddd",
                        borderRadius: "15px",
                        backgroundColor: "rgba(255, 255, 255, 0.8)"
                      }}>
                        {doctorsData?.length ? (
                          doctorsData.map((item) => (
                            <li
                              key={item.id} // Add a unique key prop
                              style={{ marginBottom: "1px", display: "flex", justifyContent: "space-between", backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                              className="suggestion-item"
                              onClick={() => { setSearchValue(item.first_name + " " + item.last_name); setShow(false); }}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={item?.profile_photo ? item?.profile_photo : "/static/images/dummy.png"}
                                  style={{ marginRight: "10px" }}
                                />
                                {item.first_name + " " + item.last_name}
                              </div>
                              <p style={{
                                fontSize: "16px",
                                fontStyle: "italic"
                              }}>Doctor</p>
                            </li>
                          ))
                        ) : (
                          "No results found"
                        )}
                        {categories?.length ? (
                          categories.map((item) => (
                            <li
                              key={item.id} // Add a unique key prop
                              style={{ marginBottom: "1px", display: "flex", justifyContent: "space-between", backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                              className="suggestion-item"
                              onClick={() => { setSearchValue(item.sub_categories_name); setShow(false); }}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Avatar
                                  alt="Remy Sharp"
                                  src={item?.image ? item?.image : "static/images/icon_05.svg"}
                                  style={{ marginRight: "10px" }}
                                />
                                {item.sub_categories_name}
                              </div>
                              <p style={{
                                fontSize: "16px",
                                fontStyle: "italic"
                              }}>Conditions</p>
                            </li>
                          ))
                        ) : (
                          "No results found"
                        )}
                      </ul>
                    </div>
                  ) : ("")} */}

                  < hr />
                  <div className="control_group">
                    <div className="input_with_clear">
                      <input
                        className="icon_location"
                        type="text"
                        placeholder="Location"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                      {inputValue && (
                        <button className="clear_button" onClick={clearInput}><CloseIcon /></button>
                      )}
                    </div>
                    {inputValue && suggestions.length > 0 && (
                      <ul className="suggestions_list">
                        {suggestions.map((suggestion: any, index: number) => (
                          <li
                            style={{ marginBottom: "1px" }}
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                            className="suggestion-item"
                          >
                            {suggestion.Place.Label}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {/* <button
                    type="button"
                    className="btnn btn_primary"
                    onClick={() => {
                      setLocationState(null);
                      getAllDoctors(searchValue);
                    }}
                  >
                    <img src="static/images/search_icon_small.svg" alt="Icon" />
                  </button> */}
                </form>
                <div className="filter_sc">
                  <div className="dropdown">
                    <Box
                      className={
                        anchorElUser
                          ? "dropdown-toggle active"
                          : "dropdown-toggle"
                      }
                      style={experienceValue ? { backgroundColor: "#29AB87" } : {}}
                      onClick={handleOpenUserMenu}
                    >
                      Experience
                    </Box>
                    <Menu
                      className="filter_dropdown_menu"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <h6>Experience</h6>
                      {/* <form> */}
                      <RadioGroup
                        className="filter_list"
                        aria-labelledby="demo-experience-label"
                        name="experience"
                        value={experienceValue}
                        onChange={handleExpChange}
                      >
                        {
                          expData && expData.length > 0 ? (
                            expData.map((item) => (
                              <FormControlLabel
                                key={item?.id}
                                className="checkbox_label"
                                value={item?.id}
                                control={<Radio />}
                                label={item?.range + " Years"}
                              />
                            ))
                          ) : (
                            <h4>N/A</h4>
                          )
                        }
                      </RadioGroup>
                      <div className="btn_flex">
                        {experienceValue ? (
                          <input
                            type="reset"
                            className="btn_link"
                            value="Clear"
                            onClick={() => { setExperienceValue(null); handleCloseUserMenu() }}
                          />
                        ) : ("")}
                        <button
                          type="submit"
                          className="btnn btn_xsm btn_primary"
                          onClick={() => handleCloseUserMenu()}
                        >
                          Close
                        </button>
                      </div>
                      {/* </form> */}
                    </Menu>
                  </div>
                  <div className="dropdown">
                    <Box
                      className={
                        anchorElUser2
                          ? "dropdown-toggle active"
                          : "dropdown-toggle"
                      }
                      onClick={handleOpenUserMenu2}
                      style={genderValue ? { backgroundColor: "#29AB87" } : {}}
                    >
                      Gender
                    </Box>
                    <Menu
                      className="filter_dropdown_menu"
                      anchorEl={anchorElUser2}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser2)}
                      onClose={handleCloseUserMenu2}
                    >
                      <h6>Gender</h6>
                      {/* <form> */}
                      <RadioGroup
                        className="filter_list"
                        aria-labelledby="demo-sort-label"
                        name="sort"
                        value={genderValue}
                        onChange={handleGenderChange}
                      >
                        <FormControlLabel
                          className="checkbox_label"
                          value="female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          className="checkbox_label"
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          className="checkbox_label"
                          value="non-binary"
                          control={<Radio />}
                          label="Non-binary"
                        />
                      </RadioGroup>
                      <div className="btn_flex">
                        {genderValue ? (
                          <input
                            type="reset"
                            className="btn_link"
                            value="Clear"
                            onClick={() => { setGenderValue(null); handleCloseUserMenu2() }}
                          />
                        ) : ("")}

                        <button
                          type="submit"
                          className="btnn btn_xsm btn_primary"
                          onClick={() => handleCloseUserMenu2()}
                        >
                          Close
                        </button>
                      </div>
                      {/* </form> */}
                    </Menu>
                  </div>
                  {/* <div className="dropdown">
                    <Box
                      className={
                        anchorElUser3
                          ? "dropdown-toggle active"
                          : "dropdown-toggle"
                      }
                      onClick={handleOpenUserMenu3}
                      style={priceValue ? { backgroundColor: "#29AB87" } : {}}
                    >
                      Price
                    </Box>
                    <Menu
                      className="filter_dropdown_menu"
                      anchorEl={anchorElUser3}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser3)}
                      onClose={handleCloseUserMenu3}
                    >
                      <h6>Price</h6>
                      <RadioGroup
                        className="filter_list"
                        aria-labelledby="demo-price-label"
                        name="price"
                        value={priceValue}
                        onChange={handlePriceChange}
                      >
                        <FormControlLabel
                          className="checkbox_label"
                          value="asc"
                          control={<Radio />}
                          label="Low to high"
                        />
                        <FormControlLabel
                          className="checkbox_label"
                          value="desc"
                          control={<Radio />}
                          label="High to low"
                        />
                      </RadioGroup>
                      <div className="btn_flex">
                        <input
                          type="reset"
                          className="btn_link"
                          value="Clear"
                          onClick={() => { setPriceValue(null); handleCloseUserMenu3() }}
                        />
                        <button
                          type="submit"
                          className="btnn btn_xsm btn_primary"
                          onClick={() => handleCloseUserMenu3()}
                        >
                          Close
                        </button>
                      </div>
                    </Menu>
                  </div> */}
                  <div className="dropdown">
                    <Box
                      className={
                        anchorElUser4
                          ? "dropdown-toggle active"
                          : "dropdown-toggle"
                      }
                      onClick={handleOpenUserMenu4}
                      style={ratingValue ? { backgroundColor: "#29AB87" } : {}}
                    >
                      Rating
                    </Box>
                    <Menu
                      className="filter_dropdown_menu"
                      anchorEl={anchorElUser4}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser4)}
                      onClose={handleCloseUserMenu4}
                    >
                      <h6>Rating</h6>
                      {/* <form> */}
                      <RadioGroup
                        className="filter_list"
                        aria-labelledby="demo-star-label"
                        name="star"
                        value={ratingValue}
                        onChange={handleRatingChange}
                      >
                        <FormControlLabel
                          className="checkbox_label"
                          value="1"
                          control={<Radio />}
                          label="1"
                        />
                        <FormControlLabel
                          className="checkbox_label"
                          value="2"
                          control={<Radio />}
                          label="2"
                        />
                        <FormControlLabel
                          className="checkbox_label"
                          value="3"
                          control={<Radio />}
                          label="3"
                        />
                        <FormControlLabel
                          className="checkbox_label"
                          value="4"
                          control={<Radio />}
                          label="4"
                        />
                        <FormControlLabel
                          className="checkbox_label"
                          value="5"
                          control={<Radio />}
                          label="5"
                        />
                      </RadioGroup>
                      <div className="btn_flex">
                        {ratingValue ? (
                          <input
                            type="reset"
                            className="btn_link"
                            value="Clear"
                            onClick={() => { setRatingValue(null); handleCloseUserMenu4() }}
                          />
                        ) : ("")}

                        <button
                          type="submit"
                          className="btnn btn_xsm btn_primary"
                          onClick={() => handleCloseUserMenu4()}
                        >
                          Close
                        </button>
                      </div>
                      {/* </form> */}
                    </Menu>
                  </div>
                  <div className="dropdown">
                    <Box
                      className={
                        anchorElUser5
                          ? "dropdown-toggle active"
                          : "dropdown-toggle"
                      }
                      onClick={handleOpenUserMenu5}
                      style={morningValue ? { backgroundColor: "#29AB87" } : {}}
                    >
                      Morning
                    </Box>
                    <Menu
                      className="filter_dropdown_menu"
                      anchorEl={anchorElUser5}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser5)}
                      onClose={handleCloseUserMenu5}
                    >
                      <h6>Morning</h6>
                      {/* <form> */}
                      <RadioGroup
                        className="filter_list"
                        aria-labelledby="demo-morning-label"
                        name="morning"
                        value={morningValue}
                        onChange={handleMorningChange}
                      >
                        <FormControlLabel
                          className="checkbox_label"
                          value="morning"
                          control={<Radio />}
                          label="Starts before 12pm"
                        />
                      </RadioGroup>
                      <div className="btn_flex">
                        {morningValue ? (
                          <input
                            type="reset"
                            className="btn_link"
                            value="Clear"
                            onClick={() => { setMorningValue(null); handleCloseUserMenu5() }}
                          />
                        ) : ("")}
                        <button
                          type="submit"
                          className="btnn btn_xsm btn_primary"
                          onClick={() => handleCloseUserMenu5()}
                        >
                          Close
                        </button>
                      </div>
                      {/* </form> */}
                    </Menu>
                  </div>
                  <div className="dropdown">
                    <Box
                      className={
                        anchorElUser6
                          ? "dropdown-toggle active"
                          : "dropdown-toggle"
                      }
                      onClick={handleOpenUserMenu6}
                      style={afterValue ? { backgroundColor: "#29AB87" } : {}}
                    >
                      Afternoon
                    </Box>
                    <Menu
                      className="filter_dropdown_menu"
                      anchorEl={anchorElUser6}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser6)}
                      onClose={handleCloseUserMenu6}
                    >
                      <h6>Afternoon</h6>
                      {/* <form> */}
                      <RadioGroup
                        className="filter_list"
                        aria-labelledby="demo-afternoon-label"
                        name="afternoon"
                        value={afterValue}
                        onChange={handleAfterChange}
                      >
                        <FormControlLabel
                          className="checkbox_label"
                          value="afternoon"
                          control={<Radio />}
                          label="Starts after 12pm"
                        />
                      </RadioGroup>
                      <div className="btn_flex">
                        {afterValue ? (
                          <input
                            type="reset"
                            className="btn_link"
                            value="Clear"
                            onClick={() => { setAfterValue(null); handleCloseUserMenu6() }}
                          />
                        ) : ("")}

                        <button
                          type="submit"
                          className="btnn btn_xsm btn_primary"
                          onClick={() => handleCloseUserMenu6()}
                        >
                          Close
                        </button>
                      </div>
                      {/* </form> */}
                    </Menu>
                  </div>

                  <div className="dropdown">
                    <Box
                      className={
                        anchorElUser7
                          ? "dropdown-toggle active"
                          : "dropdown-toggle"
                      }
                      onClick={handleOpenUserMenu7}
                      style={eveningValue ? { backgroundColor: "#29AB87" } : {}}

                    >
                      Evening
                    </Box>
                    <Menu
                      className="filter_dropdown_menu"
                      anchorEl={anchorElUser7}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser7)}
                      onClose={handleCloseUserMenu7}
                    >
                      <h6>Evening</h6>
                      {/* <form> */}
                      <RadioGroup
                        className="filter_list"
                        aria-labelledby="demo-evening-label"
                        name="evening"
                        value={eveningValue}
                        onChange={handleEveningChange}
                      >
                        <FormControlLabel
                          className="checkbox_label"
                          value="evening"
                          control={<Radio />}
                          label="Starts after 5pm"
                        />
                      </RadioGroup>
                      <div className="btn_flex">
                        {eveningValue ? (
                          <input
                            type="reset"
                            className="btn_link"
                            value="Clear"
                            onClick={() => { setEveningValue(null); handleCloseUserMenu7() }}
                          />
                        ) : ("")}

                        <button
                          type="submit"
                          className="btnn btn_xsm btn_primary"
                          onClick={() => handleCloseUserMenu7()}
                        >
                          Close
                        </button>
                      </div>
                      {/* </form> */}
                    </Menu>
                  </div>

                  <div className="dropdown">
                    <Box
                      className={
                        anchorElUser8
                          ? "dropdown-toggle active"
                          : "dropdown-toggle"
                      }
                      onClick={handleOpenUserMenu8}
                      style={weekendValue ? { backgroundColor: "#29AB87" } : {}}
                    >
                      Weekend
                    </Box>
                    <Menu
                      className="filter_dropdown_menu"
                      anchorEl={anchorElUser8}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser8)}
                      onClose={handleCloseUserMenu8}
                    >
                      <h6>Weekend</h6>
                      {/* <form> */}
                      <RadioGroup
                        className="filter_list"
                        aria-labelledby="demo-Weekend-label"
                        name="Weekend"
                        value={weekendValue}
                        onChange={handleWeekendChange}
                      >
                        <FormControlLabel
                          className="checkbox_label"
                          value="Weekend"
                          control={<Radio />}
                          label="Weekend"
                        />
                      </RadioGroup>
                      <div className="btn_flex">
                        {weekendValue ? (
                          <input
                            type="reset"
                            className="btn_link"
                            value="Clear"
                            onClick={() => { setWeekendValue(null); handleCloseUserMenu8() }}
                          />
                        ) : ("")}

                        <button
                          type="submit"
                          className="btnn btn_xsm btn_primary"
                          onClick={() => handleCloseUserMenu8()}
                        >
                          Close
                        </button>
                      </div>
                      {/* </form> */}
                    </Menu>
                  </div>
                </div>
                <div className="hd_4">
                  <h2 className="mb-0">
                    Result Showing {totalCount ? totalCount : "0"} {totalCount === 1 ? "Doctor" : "Doctors"}

                  </h2>
                  <div className="doctor_date">
                    <a onClick={handlePrevDayClick}>
                      <ArrowBackIosNewIcon />
                    </a>
                    <span>
                      {currentDate ? formatDate(currentDate) : ""}
                    </span>
                    <a onClick={handleNextDayClick}>
                      <ArrowForwardIosIcon />
                    </a>
                  </div>
                </div>
                <div className="box_listing">
                  {doctorsData?.length ? (
                    <div className="gap_p hd_4">
                      <AcupunctureCard
                        type={locationState?.value}
                        doctorsData={doctorsData}
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                      />
                    </div>
                  ) : (
                    <div className="gap_p hd_4">
                      <h2>We couldn't find any doctors for you</h2>
                    </div>

                  )}

                  {doctorsData?.length ? (
                    <SitePagination
                      module={doctorsData}
                      page={page}
                      onPageChange={onPageChange}
                      totalPages={totalPages}
                      setPage={setPage}
                    />
                  ) : undefined}
                </div>
              </div>
              <div className="right_s">
                <div className="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13720.683685743084!2d76.709613!3d30.713595!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feef63fffffdd%3A0xe08a861af795e737!2sApptunix%20-%20Mobile%20App%20Development%20Company!5e0!3m2!1sen!2sin!4v1707717234796!5m2!1sen!2sin"></iframe>
                  {/* <View style={{ width: "500px", height: "800px" }}>
                    <Flex direction={'column'} alignItems={'center'}>
                  
                    <MapListingView />
                    </Flex>
                  </View>  */}

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

