// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "../../layouts";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  IconButton,
  InputAdornment,
  TextField,
  SelectChangeEvent,
  Select,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AddIcon from "@mui/icons-material/Add";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  STORAGE_KEYS,
  getFromStorage,
  setToStorage,
  showError,
  showToast,
  Loader,
} from "../../constants";
import { usePostProfileSetupMutation } from "../../services/auth";
import { isNumber, isString } from "../../utils/validations";
import { UploadMedia } from "../../utils/mediaUpload";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";
import Header from "../../layouts/header";
import { Footer } from "../../layouts/footer/footer";


const ProfileSetup = () => {

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        console.log(file, "FILE");
        const res = await UploadMedia(file);
        if (res?.status === 200) {
          formik.setFieldValue("image", res?.url);
          showToast("Image uploaded successfully")
        } else {
          showError(res?.message);
        }
      } else {
        // Display an error message for non-image files
        showError("Failed to upload image");
      }
    }
  };

  const location = useLocation();
  const { state } = location;
  const userData = useAuth();
  const getData = getFromStorage(STORAGE_KEYS.res) as any;
  const navigate = useNavigate();
  const [updateProfile, { isLoading }] = usePostProfileSetupMutation();
  const [error, setError] = useState(false);
  const [value, setValue] = useState(" ");
  const [showPassword1, setShowPassword1] = useState(false);
  const dispatch = useAppDispatch();
  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleMouseDownPassword1 = (event: any) => {
    event.preventDefault();
  };

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  const [selectField, setSelectField] = React.useState(" ");
  console.log(selectField, "okkass");
  const handleChange = (event: SelectChangeEvent) => {
    setSelectField(event.target.value as string);
  };
  const handleChangeStatus = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: getData ? JSON.parse(getData)?.email : "",
      fullName: userData?.name || "",
      phone: userData?.phone_number || "",
      countryCode: userData?.country_code || "+1",
      countryName: userData?.countryName || "US",
      dob: userData?.DOB || "",
      city: userData?.city || "",
      zipcode: userData?.zipcode || "",
      gender: "",
      password: "",
      confirmPassword: "",
      height: userData?.height?.toString() || "",
      weight: userData?.weight?.toString() || "",
      martialStatus: "",
      address: userData?.country || "",
      occupation: userData?.occupation || "",
      id: 0,
      image: userData?.profile_photo || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Please enter a valid email"
        ),
      fullName: Yup.string()
        .required("Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(100, "Maximum 100 characters is required")
        .matches(
          /^[A-Za-z\s'-]+$/,
          "Name must contain only alphabets"
        ),
      city: Yup.string()
        .required("City is required"),
      zipcode: Yup.string()
        .required("zipcode is required"),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Minimum 6 digits are required")
        .max(16, "Maximum 16 digits are required"),
      countryCode: Yup.string().required("Country code is required"),
      dob: Yup.string().required("Date of Birth is required"),
      height: Yup.string()
        .required("Height is required")
        .matches(
          /^(?!0\d)\d{1,3}(\.\d{1,2})?$/,
          "Height must be a valid number "
        ),
      weight: Yup.string()
        .required("Weight is required")
        .matches(
          /^(?!0\d)\d{1,3}(\.\d{1,3})?$/,
          "Weight must be a valid number, maximum of 3 digits"
        ),

      address: Yup.string().required("Address is required").max(200, "You can't add more than 200 characters"),
      occupation: Yup.string().required("Occupation is required").max(100, "You can't add more than 100 characters")
        .matches(
          /^[A-Za-z\s'-]+$/,
          "Occupation must not contain numbers"
        ),
      password: Yup.string()
        .label("new Password")
        .required("Password is required")
        .matches(
          /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[0-9])[^\s]{8,}$/,
          "Password must be at least 8 characters long and include 1 uppercase, 1 lowercase, and 1 special character."
        ),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Confirm password is required"),
    }),
    if(userData) {
      formik.setFieldValue('name', userData?.name)
      formik.setFieldValue('phone_number', userData?.phone_number)
      formik.setFieldValue('profile_photo', userData?.profile_photo)
      formik.setFieldValue('country_code', userData?.country_code)
      formik.setFieldValue('country_name', userData?.country_name)
      formik.setFieldValue('DOB', userData?.DOB)
      formik.setFieldValue('city', userData?.city)
      formik.setFieldValue('zipcode', userData?.zipcode)
      formik.setFieldValue('gender', userData?.gender)
      formik.setFieldValue('height', userData?.height)
      formik.setFieldValue('weight', userData?.weight)
      formik.setFieldValue('marital_status', userData?.marital_status)
      formik.setFieldValue('country', userData?.country)
      formik.setFieldValue('occupation', userData?.occupation)
    },
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      setError(false);
      let body = {
        profile_photo: values.image,
        email: values.email,
        name: values.fullName,
        phone_number: formik.values.phone,
        country_code: values.countryCode,
        country_name: values.countryName,
        DOB: values.dob,
        gender: selectField,
        height: values.height,
        city: values.city,
        zipcode: values.zipcode,
        weight: values.weight,
        marital_status: value,
        country: values.address,
        occupation: values.occupation,
        id: getData ? JSON.parse(getData)?.id : 0,
        password: values.password,
        password_confirmation: values.password,
      };

      try {
        const response = await updateProfile(body).unwrap();
        if (response?.status === 200) {
          // if (response?.data?.isEmailVerify) {
          showToast("Profile setup successfully");
          // const newData = response?.data?.id;
          setToStorage(STORAGE_KEYS.stepID, response?.data?.id);

          const token = getFromStorage(STORAGE_KEYS?.token);
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: token || null,
            }),

          );
          navigate("/step-1");
        }
      } catch (errors: any) {
        console.log(errors, "errrr");

        showError(errors?.data?.errors);
      }
    },
  });
  console.log();

  console.log(userData, "userData...............");

  const handleChangePhone = (phone: any, country: any) => {
    // formik.setFieldValue("phone", val);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  useEffect(() => {
    if (getData) {
      formik.setFieldValue("countryCode", JSON.parse(getData)?.countryCode);
    }
  }, []);




  useEffect(() => {
    if (userData) {
      setSelectField(userData?.gender || " ");
      setValue(userData?.marital_status || " ");
    }
  }, []);

  return (
    <>
      <Header cms />
      <Loader isLoad={isLoading} />
      <main className="content auth_page">
        <section className="auth_sc uh_spc">
          <div className="conta_iner">
            <div className="inner">
              <div className="hd_4 text_center">
                {/* <a onClick={() => navigate("/login")} className="back_icon">
                    <ArrowBackIcon />
                  </a> */}
                <h2>Profile Setup</h2>
              </div>
              <form className="form" onSubmit={formik.handleSubmit}>
                <div className="control_group text_center">
                  {formik.values.image ? (
                    <div className="upload_image">
                      <figure>
                        <img
                          src={
                            formik.values.image
                              ? formik.values.image
                              : "static/images/dummy.png"
                          }
                          alt="icon"
                        />
                      </figure>
                      <div className="action">
                        <CancelIcon
                          onClick={() => {
                            formik.setFieldValue("image", "");
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="upload_image">
                      <figure >
                        <img src="static/images/dummy.png" alt="icon" />
                      </figure>
                      <div className="action">
                        <AddIcon />
                        <input
                          placeholder="img"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleImageUpload}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="control_group">
                  <label>Full name</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Name"
                    className="text_field"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    name="fullName"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                </div>
                <div className="control_group">
                  <label>Email Id</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Email"
                    className="text_field"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    disabled={JSON.parse(getData)?.email}
                    name="email"
                    value={formik.values.email}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="control_group">
                  <label>Phone number</label>
                  <PhoneInput
                    country={"us"}
                    placeholder="0 (000) 000-000"
                    enableSearch={true}
                    disabled={JSON.parse(getData)?.phone}
                    inputStyle={{ width: "100%" }}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent default behavior (form submission)
                        formik.handleSubmit(); // Manually submit the form
                      }
                    }}
                    value={formik.values.countryCode + formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <h6 className="err_msg">
                      {formik.touched.phone && formik.errors.phone}
                    </h6>
                  ) : (
                    ""
                  )}
                </div>
                <div className="control_group">
                  <label>Gender</label>
                  <Select
                    fullWidth
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    label="social-account"
                    value={selectField}
                    onChange={handleChange}
                    name="gender"
                    displayEmpty
                  >
                    <MenuItem disabled value=" ">
                      Select
                    </MenuItem>
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                  {error && selectField?.length < 2 ? (
                    <h6 className="err_msg">Gender is required</h6>
                  ) : (
                    ""
                  )}
                </div>
                <div className="control_group">
                  <label>Date of birth</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter here"
                    fullWidth
                    name="dob"
                    className="text_field"
                    type="date"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dob}
                    helperText={formik.touched.dob && formik.errors.dob}
                    inputProps={{
                      // max: new Date().toISOString().split("T")[0],
                      max: moment().subtract(18, "years").format("YYYY-MM-DD"),
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                    InputProps={{ disableUnderline: true }}
                  />
                </div>
                <div className="control_group">
                  <label>Height( in cm )</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Height"
                    className="text_field"
                    fullWidth
                    name="height"
                    onChange={(e) => {
                      const value = e.target.value;
                      // Ensure it's a valid number with up to 2 decimal places and 10 digits in total
                      const regex = /^\d{0,8}(\.\d{0,2})?$/;
                      if (regex.test(value) || value === "") {
                        // Pass the value to handleChange if it's valid
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.height}
                    helperText={formik.touched.height && formik.errors.height}
                  />
                </div>
                <div className="control_group">
                  <label>Weight( in kg )</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Weight"
                    className="text_field"
                    fullWidth
                    name="weight"
                    onChange={(e) => {
                      const value = e.target.value;
                      // Ensure it's a valid number with up to 2 decimal places and 10 digits in total
                      const regex = /^\d{0,8}(\.\d{0,2})?$/;
                      if (regex.test(value) || value === "") {
                        // Pass the value to handleChange if it's valid
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.weight}
                    helperText={formik.touched.weight && formik.errors.weight}
                  />
                </div>
                <div className="control_group">
                  <label>Martial status</label>
                  <Select
                    fullWidth
                    displayEmpty
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    label="social-account"
                    value={value}
                    onChange={handleChangeStatus}
                    name="gender"
                  >
                    <MenuItem disabled value=" ">
                      Select
                    </MenuItem>
                    <MenuItem value={"Married"}>Married</MenuItem>
                    <MenuItem value={"Un-married"}>Un-married</MenuItem>
                  </Select>
                  {error && value?.length < 2 ? (
                    <h6 className="err_msg">Martial status is required</h6>
                  ) : (
                    ""
                  )}
                </div>
                <div className="control_group">
                  <label>Address</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Address"
                    fullWidth
                    className="text_field"
                    name="address"
                    onChange={(val) => {
                      // if (
                      //   val.target.value === " " ||
                      //   val.target.value === "."
                      // ) {
                      // } else if (val.target.value) {
                      formik.handleChange(val);
                      // }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </div>
                <div className="control_group">
                  <label>City</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter your city"
                    fullWidth
                    className="text_field"
                    name="city"
                    onChange={(val) => {
                      // if (
                      //   val.target.value === " " ||
                      //   val.target.value === "."
                      // ) {
                      // } else if (val.target.value) {
                      formik.handleChange(val);
                      // }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </div>
                <div className="control_group">
                  <label>Post code/ZIP</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Address"
                    fullWidth
                    className="text_field"
                    name="zipcode"
                    onChange={(val) => {
                      // if (
                      //   val.target.value === " " ||
                      //   val.target.value === "."
                      // ) {
                      // } else if (val.target.value) {
                      formik.handleChange(val);
                      // }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.zipcode}
                    helperText={formik.touched.zipcode && formik.errors.zipcode}
                  />
                </div>
                <div className="control_group">
                  <label>Occupation</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Occupation"
                    className="text_field"
                    fullWidth
                    name="occupation"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.occupation}
                    helperText={
                      formik.touched.occupation && formik.errors.occupation
                    }
                  />
                </div>
                <div className="control_group">
                  <label>Password</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Password"
                    fullWidth
                    className="text_field"
                    name="password"
                    type={showPassword1 ? "text" : "password"}
                    variant="outlined"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword1}
                            edge="end"
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="control_group">
                  <label>Confirm Password</label>
                  <TextField
                    hiddenLabel
                    className="text_field"
                    placeholder="Enter Password"
                    fullWidth
                    name="confirmPassword"
                    type={showPassword2 ? "text" : "password"}
                    variant="outlined"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                            edge="end"
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form_btn">
                  <button
                    type="submit"
                    className="btnn btn_xsm btn_primary w_100"
                    onClick={() => setError(true)}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default ProfileSetup;
